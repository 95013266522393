import { FormHelperText, InputLabel, FormControl, Select, Stepper, Step, StepLabel, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, Checkbox, ListItemText, MenuItem, Box, TextField, LinearProgress, Switch, FormControlLabel, FormLabel } from "@mui/material";
import { IGasForm, IGasSelectField, SuccessMessage, IGasTextField, DetailInfo } from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { insert, getExist } from "../../../store/catalogos/clientes";
import { getEstadoByCodigoPostal } from "../../../store/consultas/catalogosSAT";
import { ActivateDialog } from "./activateDialog";

function StepPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`step-panel-${index}`}
            aria-labelledby={`step-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export function InsertDialog({ open, onClose, postSubmit, onRefresh, formasPagoAdmin, catalagosSAT }) {
    const dispatch = useDispatch();
    const { paisesSAT, usosCFDISAT } = catalagosSAT
    const { loading: clientesLoading, error } = useSelector(state => state.clientes);
    const { loading: catalagosLoading, error: catalagosError } = useSelector(state => state.catalogosSAT);
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({});
    const successMsg = useModal(false);
    const modalActivate = useModal(false);

    const [selectedUsoCFDI, setSelectedUsoCFDI] = useState('0');
    const [selectedPais, setSelectedPais] = useState('0');
    const [selectedEstado, setSelectedEstado] = useState('0');
    const [regimenesSeleccionados, setRegimenesSeleccionados] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [checked, setChecked] = useState([0]);
    const [usoCFDIFiltrado, setUsoCFDIFiltrado] = useState([]);
    const [paisesFiltrados, setPaisesFiltrados] = useState([]);
    const [tipoPersonaState, setTipoPersonaState] = useState(0);
    const [codigoPostal, setCodigoPostal] = useState('')
    const [activarCliente, setActivarCliente] = useState({});
    const [usoYRegPublicoGeneral, setUsoYRegPublicoGeneral] = useState({});
    const [esPublico, setEsPublico] = useState(false);
    const [telefono, setTelefono] = useState('');

    const emptyGuid = "00000000-0000-0000-0000-000000000000";

    const handleNext = () => {
        setErrorMessage("");
        setErrors({});
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleUsoCFDIChange = (event) => {
        setChecked([0]);
        const selectedId = event.target.value;
        setSelectedUsoCFDI(selectedId);
        const selectedOption = usoCFDIFiltrado.find(option => option.id === selectedId);
        const filtrados = selectedOption.regimenesFiscalesSAT.filter(
            (regimen) => regimen.tipoPersona === tipoPersonaState || regimen.tipoPersona === 2
        );
        if (selectedOption) {
            setRegimenesSeleccionados(filtrados.sort((a, b) => a.clave.localeCompare(b.clave)));
        } else {
            setRegimenesSeleccionados([]);
        }
    };

    const handlePaisSATChange = (event) => {
        setSelectedEstado('0')
        const selectedId = event.target.value;
        setSelectedPais(selectedId)
        const selectedOption = paisesSAT.find(option => option.id === selectedId);
        if (selectedOption) {
            const estadosCopia = [...selectedOption.estados];
            setPaisesFiltrados(estadosCopia.sort((a, b) => a.descripcion.localeCompare(b.descripcion)));
        } else {
            setPaisesFiltrados([]);
        }
    };

    const handleEstadoChange = (event) => {
        setSelectedEstado(event.target.value);
    };

    const handleRFCChange = (rfc) => {
        var tipoPersona = rfc === 13 ? 0 : 1;
        if (tipoPersona !== tipoPersonaState || esPublico) {
            setChecked([0]);
            setRegimenesSeleccionados([]);
            setSelectedUsoCFDI('0');
        }
        setTipoPersonaState(tipoPersona);
        var UsosCFDIFiltrados = usosCFDISAT?.sort((a, b) => a.clave.localeCompare(b.clave))
            .filter(option => option.tipoPersona === tipoPersona || option.tipoPersona === 2);
        setUsoCFDIFiltrado(UsosCFDIFiltrados);
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return
        setErrorMessage("");
        setRegimenesSeleccionados([]);
        setSelectedEstado('0')
        setActiveStep(0);
        setChecked([0]);
        onRefresh();
        modalActivate.close();
        onClose();
    }

    const handleSubmit = (data) => {
        setErrorMessage("");
        setErrors({});
        var postFail = false;
        const rfcFormatoRegex = data.RFC.length < 13
            ? /^([A-ZÑa-zñ]|\\&){3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Za-z0-9]{3}$/
            : /^([A-ZÑa-zñ]|\\&){4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Za-z0-9]{3}$/;
        const rfcMinusculasRegex = /[a-z]/;
        const emojisRegex = /([\u203C-\u3299]|[\uD83C-\uDBFF\uDC00-\uDFFF]+)/g;
        const cpRegex = /^\d{5}$/

        if (activeStep === 0) {

            const RFCFormatoIncorrecto = !rfcFormatoRegex.test(data.RFC);
            if (RFCFormatoIncorrecto) {
                setErrorMessage((e) => ({
                    ...e,
                    RFC: "Formato de RFC incorrecto.",
                }));
                postFail = true;
            }
            const RFCMinusculas = rfcMinusculasRegex.test(data.RFC);
            if (RFCMinusculas) {
                setErrorMessage((e) => ({
                    ...e,
                    RFC: "RFC no debe contener letras minúsculas.",
                }));
                postFail = true;
            }
            if (data.RFC === 'XEXX010101000') {
                setErrorMessage((e) => ({
                    ...e,
                    RFC: "RFC no administrable.",
                }));
                postFail = true;
            }
            if (data.FormaPagoAdminId === '0') {
                setErrorMessage((e) => ({
                    ...e,
                    FormaPagoAdminId: "Forma pago es requerida.",
                }));
                postFail = true;
            }
            const razonSocialEmojis = emojisRegex.test(data.RazonSocial);
            if (razonSocialEmojis) {
                setErrorMessage((e) => ({
                    ...e,
                    RazonSocial: "Caracteres no alfanuméricos no permitidos.",
                }));
                postFail = true;
            }
            if (data.RazonSocial === '' || data.RazonSocial === null) {
                setErrorMessage((e) => ({
                    ...e,
                    RazonSocial: "Razón social es requerida.",
                }));
                postFail = true;
            }
            if (data.RazonSocial === '|') {
                setErrorMessage((e) => ({
                    ...e,
                    RazonSocial: "Formato incorrecto.",
                }));
                postFail = true;
            }
            const nombreComercialEmojis = emojisRegex.test(data.NombreComercial);
            if (nombreComercialEmojis) {
                setErrorMessage((e) => ({
                    ...e,
                    NombreComercial: "Caracteres no alfanuméricos no permitidos.",
                }));
                postFail = true;
            }
            if (data.NombreComercial === '' || data.NombreComercial == null) {
                setErrorMessage((e) => ({
                    ...e,
                    NombreComercial: "Nombre comercial es requerido.",
                }));
                postFail = true;
            }
            if (postFail)
                return

            data.RFC === 'XAXX010101000' ? setEsPublico(true) : setEsPublico(false);

            handleRFCChange(data.RFC.length)
            handleNext();
            return;

        } else if (activeStep === 1) {
            if (!esPublico) {
                if (selectedUsoCFDI === emptyGuid || selectedUsoCFDI === null || selectedUsoCFDI === '0') {
                    setErrorMessage((e) => ({
                        ...e,
                        UsoCFDISATId: "Uso de CDFI es requerido.",
                    }));
                    postFail = true;
                }
                const filteredChecked = checked.filter(item => item !== 0 && typeof item === 'string');
                if ((filteredChecked.length === 0 || filteredChecked === null) && regimenesSeleccionados.length > 0) {
                    setErrorMessage((e) => ({
                        ...e,
                        RegimenesFiscalesSATIds: "Seleccione al menos un régimen fiscal.",
                    }));
                    postFail = true;
                }
                if (postFail)
                    return
            } else {
                setSelectedUsoCFDI(usoYRegPublicoGeneral.usoId);
                setChecked([usoYRegPublicoGeneral.regId]);
            }
            handleNext();
            return;
        } else if (activeStep === 2) {
            const direccionEmojis = emojisRegex.test(data.Direccion);

            if (direccionEmojis) {
                setErrorMessage((e) => ({
                    ...e,
                    Direccion: "Caracteres no alfanuméricos no permitidos.",
                }));
                postFail = true;
            }

            const cpFormato = !cpRegex.test(codigoPostal);
            if (cpFormato) {
                setErrorMessage((e) => ({
                    ...e,
                    CodigoPostal: "Ingrese 5 números.",
                }));
                postFail = true;
            }

            if (!codigoPostal) {
                setErrorMessage((e) => ({
                    ...e,
                    CodigoPostal: "Código Postal es requerido.",
                }));
                postFail = true;
            }

            if (selectedPais === emptyGuid || selectedPais === null || selectedPais === '0') {
                setErrorMessage((e) => ({
                    ...e,
                    PaisSATId: "País es requerido.",
                }));
                postFail = true;
            }

            if (postFail)
                return

            handleNext();
            return;
        }

        const checkedFiltrados = checked.filter(item => item !== 0 && typeof item === 'string');
        data.UsoCFDISATId = selectedUsoCFDI;
        data.RegimenesFiscalesSATIds = checkedFiltrados;

        data.PaisSATId = selectedPais;
        data.EstadoSATId = selectedEstado !== "0" ? selectedEstado : null;
        data.CodigoPostal = codigoPostal;

        if (data.Nombre) {
            data.Contacto = {
                Nombre: data.Nombre,
                Puesto: data.Puesto,
                CorreoElectronico: data.CorreoElectronico,
                Telefono: telefono,
                ...(data.CorreoElectronico.length > 0 ? { recibirCorreosCFDI: true } : { recibirCorreosCFDI: false })
            }
        }
        else {
            data.Contacto = null
        }

        delete data.Nombre;
        delete data.Correo;

        dispatch(insert(data))
            .then(hasDispatchError)
            .then(onClose)
            .then(successMsg.open)
            .then(postSubmit)
            .catch(() => { });
    };

    const handleKeyPress = (event) => {
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
        const isNumber = /^\d$/.test(event.key);
        if (!isNumber && !allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    const handleCPChange = (event) => {
        setErrors({})
        const cpRegex = /^\d{5}$/
        const codigoPostal = event.target.value;
        const cpFormato = cpRegex.test(codigoPostal);
        const paisMexico = paisesSAT.find(pais => pais.descripcion === "México");
        setCodigoPostal(codigoPostal)

        if (codigoPostal.length === 5) {
            if (cpFormato && selectedPais === paisMexico.id) {
                dispatch(getEstadoByCodigoPostal(codigoPostal))
                    .then(({ payload }) => {
                        payload?.status === 409
                            ? setSelectedEstado('0')
                            : setSelectedEstado(payload.estadoSATId)
                    })
                    .catch(() => { });
            }
        }
    }

    function initDialog() {
        if (paisesSAT.length > 0) {
            paisesSAT.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
            const paisMexico = paisesSAT.find(pais => pais.descripcion === "México");
            const paisInicial = paisMexico || paisesSAT[0];
            setSelectedPais(paisInicial.id);
            const estadosCopia = paisInicial.estados ? [...paisInicial.estados] : [];
            setPaisesFiltrados(estadosCopia.sort((a, b) => a.descripcion.localeCompare(b.descripcion)));
        }
        if (usosCFDISAT.length > 0) {
            const usoPubGen = usosCFDISAT.find(uso => uso.clave === "S01");
            const regPubGen = usoPubGen.regimenesFiscalesSAT.find(reg => reg.clave === "616");
            setUsoYRegPublicoGeneral({
                usoId: usoPubGen.id, usoDes: `${usoPubGen.clave} - ${usoPubGen.descripcion}`,
                regId: regPubGen.id, regDes: `${regPubGen.clave} - ${regPubGen.descripcion}`
            })
        }
        modalActivate.close();
        setSelectedUsoCFDI('0');
        setUsoCFDIFiltrado([]);
        setCodigoPostal('');
        setSelectedEstado('0')
        setActiveStep(0);
        setErrors({});
        setErrorMessage("");
        setRegimenesSeleccionados([]);
        setChecked([0]);
        setTelefono('');
    }

    const assignErrors = () => {
        if (open & (error.status === 400)) setErrors(getModelErrors(error.message));
    };

    const assignCatalagosError = () => {
        if (open & (catalagosError.status === 409)) setErrors((prevErrors) => ({
            ...prevErrors,
            codigoPostal: 'Código postal no existe.',
        }));
    };

    const existeRFC = (e) => {
        setErrorMessage("");
        if (e.target.value.length >= 12 && e.target.value !== 'XAXX010101000') {
            const rfcFormatoRegex = e.target.value.length < 13
                ? /^([A-ZÑa-zñ]|\\&){3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Za-z0-9]{3}$/
                : /^([A-ZÑa-zñ]|\\&){4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Za-z0-9]{3}$/;

            const RFCFormatoIncorrecto = rfcFormatoRegex.test(e.target.value);
            if (RFCFormatoIncorrecto && e.target.value !== "XEXX010101000") {
                dispatch(getExist(e.target.value))
                    .then((response) => {
                        if (response.payload?.status !== 409) {
                            setActivarCliente({
                                rfc: e.target.value,
                                id: response.payload?.id,
                                activo: response.payload?.activo
                            })
                            modalActivate.open();
                        }
                    })
                    .catch(() => { });
            }
        }
    }

    const handleKeyPressTelefono = (event) => {
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
        const isNumber = /^\d$/.test(event.key);

        if (!isNumber && !allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    const handleSetTelefono = (event) => {
        setTelefono(event.target.value)
    }

    useEffect(initDialog, [open]);
    useEffect(assignErrors, [error]);
    useEffect(assignCatalagosError, [catalagosError]);
    const steps = ['Datos', 'Configuraciones', 'Dirección', 'Contacto'];

    return (
        <>
            <Dialog
                maxWidth='sm'
                open={open}
                onClose={handleClose}
                fullWidth
                disableEscapeKeyDown>
                {(catalagosLoading.getEstadoByCodigoPostal || clientesLoading.getExist) && <LinearProgress />}
                <DialogTitle onClose={onClose}>Agregar cliente</DialogTitle>
                <DialogContent dividers={true}>
                    <IGasForm id='form-update-dialog' onSubmit={handleSubmit}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <StepPanel value={activeStep} index={0}>
                            {formasPagoAdmin.length === 0 && <>Cargando...</>}
                            {formasPagoAdmin.length > 0 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <IGasTextField
                                            name="RFC"
                                            label='RFC'
                                            defaultValue=''
                                            autoFocus={true}
                                            error={errorMessage?.RFC}
                                            maxLength={13}
                                            inputProps={{
                                                onBlur: (e) => {
                                                    existeRFC(e)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IGasSelectField
                                            name="FormaPagoAdminId"
                                            label='Forma de Pago Default'
                                            defaultValue={'0'}
                                            error={errorMessage?.FormaPagoAdminId}
                                        >
                                            <MenuItem key={'0'} value='0' disabled>
                                                <em>Seleccione forma de pago</em>
                                            </MenuItem>
                                            {formasPagoAdmin.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {`${option.descripcion}`}
                                                </MenuItem>
                                            ))}
                                        </IGasSelectField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <IGasTextField
                                            name="RazonSocial"
                                            label='Razón Social'
                                            defaultValue=''
                                            error={errorMessage?.RazonSocial}
                                            maxLength={300}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <IGasTextField
                                            name="NombreComercial"
                                            label='Nombre Comercial'
                                            defaultValue=''
                                            error={errorMessage?.NombreComercial}
                                            maxLength={100}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </StepPanel>
                        <StepPanel value={activeStep} index={1}>
                            {usosCFDISAT.length === 0 && <>Cargando...</>}
                            {usosCFDISAT.length > 0 && (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        {esPublico ? (
                                            <DetailInfo
                                                title="Uso CFDI"
                                                description={usoYRegPublicoGeneral.usoDes}
                                            />)
                                            :
                                            (<>
                                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth error={!!errorMessage?.UsoCFDISATId}>
                                                    <InputLabel variant="standard" >
                                                        Uso CFDI Default
                                                    </InputLabel>
                                                    <Select
                                                        name="UsoCFDISATId"
                                                        label={"Uso CFDI"}
                                                        value={selectedUsoCFDI}
                                                        error={!!errorMessage?.UsoCFDISATId}
                                                        fullWidth
                                                        onChange={handleUsoCFDIChange}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 300,
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value='0' disabled>
                                                            <em>Seleccione un uso CFDI</em>
                                                        </MenuItem>
                                                        {usoCFDIFiltrado?.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {`${option.clave} - ${option.descripcion.length < 57 ? option.descripcion : option.descripcion.substring(0, 57)}`}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errorMessage?.UsoCFDISATId && (
                                                        <FormHelperText>{errorMessage.UsoCFDISATId}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </>)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {esPublico ? (
                                            <DetailInfo
                                                title="Regimene fiscale SAT"
                                                description={usoYRegPublicoGeneral.regDes}
                                            />)
                                            :
                                            (<>
                                                {regimenesSeleccionados.length > 0 && (<InputLabel variant="standard" sx={{
                                                    fontSize: '0.7rem',
                                                    color: 'gray',
                                                }}>
                                                    Regímenes Fiscales Default
                                                </InputLabel>)}
                                                <FormControl error={!!errorMessage?.RegimenesFiscalesSATIds}>
                                                    <List dense={true}
                                                        name="RegimenesFiscalesSATIds"
                                                        label='Regimenes fiscales SAT'
                                                        sx={{
                                                            maxHeight: 200,
                                                            overflow: 'auto',
                                                        }}
                                                    >
                                                        {regimenesSeleccionados && regimenesSeleccionados.length > 0 ? (
                                                            regimenesSeleccionados.map((regimen) => (
                                                                <ListItem key={regimen.id} disablePadding>
                                                                    <ListItemButton role={undefined} onClick={handleToggle(regimen.id)} dense>
                                                                        <Checkbox
                                                                            edge="start"
                                                                            checked={checked.includes(regimen.id)}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                        />
                                                                        <ListItemText
                                                                            primary={`${regimen.clave} - ${regimen.descripcion}`}
                                                                        />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            ))
                                                        ) : (
                                                            <ListItem>
                                                            </ListItem>
                                                        )}
                                                    </List>
                                                    {errorMessage?.RegimenesFiscalesSATIds && (
                                                        <FormHelperText>{errorMessage.RegimenesFiscalesSATIds}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </>)}
                                    </Grid>
                                </Grid>
                            )}
                        </StepPanel>
                        <StepPanel value={activeStep} index={2}>
                            {paisesSAT.length === 0 || catalagosLoading.getEstadoByCodigoPostal && <>Cargando...</>}
                            {paisesSAT.length > 0 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={10} sx={{ marginTop: '3px' }}>
                                        <IGasTextField
                                            name="Direccion"
                                            label='Dirección'
                                            defaultValue=''
                                            error={errorMessage?.Direccion}
                                            maxLength={200}
                                        />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <FormControl variant="standard" fullWidth error={!!errorMessage?.CodigoPostal}>
                                            <TextField
                                                name="CodigoPostal"
                                                label="C.P."
                                                variant="standard"
                                                value={codigoPostal}
                                                onChange={handleCPChange}
                                                onKeyDown={handleKeyPress}
                                                inputProps={{
                                                    maxLength: 5,
                                                }}
                                            />
                                            {errorMessage?.CodigoPostal && (
                                                <FormHelperText>{errorMessage?.CodigoPostal}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl variant="standard" fullWidth error={!!errorMessage?.PaisSATId}>
                                            <InputLabel variant="standard" >
                                                País
                                            </InputLabel>
                                            <Select
                                                name="PaisSATId"
                                                label={"País"}
                                                value={selectedPais}
                                                fullWidth
                                                onChange={handlePaisSATChange}
                                                disabled={catalagosLoading.getEstadoByCodigoPostal}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 200,
                                                        },
                                                    },
                                                }}
                                            >
                                                {paisesSAT
                                                    .map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {`${option.descripcion}`}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            {errorMessage?.PaisSATId && (
                                                <FormHelperText>{errorMessage.PaisSATId}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl variant="standard" fullWidth error={!!errorMessage?.EstadoSATId}>
                                            <InputLabel variant="standard" >
                                                Estado
                                            </InputLabel>
                                            <Select
                                                name="EstadoSATId"
                                                label={"Estado"}
                                                value={selectedEstado}
                                                fullWidth
                                                onChange={handleEstadoChange}
                                                disabled={catalagosLoading.getEstadoByCodigoPostal}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 200,
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value='0' disabled>
                                                    <em>Seleccione un estado (opcional)</em>
                                                </MenuItem>
                                                {paisesFiltrados.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {`${option.descripcion}`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errorMessage?.EstadoSATId && (
                                                <FormHelperText>{errorMessage.EstadoSATId}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}
                        </StepPanel>
                        <StepPanel value={activeStep} index={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={9} >
                                    <IGasTextField
                                        name="Nombre"
                                        label='Nombre'
                                        defaultValue=''
                                        error={errors?.nombre}
                                        maxLength={300}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <IGasTextField
                                        name="Puesto"
                                        label='Puesto'
                                        defaultValue={''}
                                        error={errors?.puesto}
                                        maxLength={50}
                                    />
                                </Grid>
                                <Grid item xs={9} >
                                    <IGasTextField
                                        name="CorreoElectronico"
                                        label='Correo electrónico'
                                        defaultValue=''
                                        error={errors?.correoelectronico}
                                        maxLength={320}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <FormControl variant="standard" fullWidth error={!!errors?.telefono}>
                                        <TextField
                                            name="Telefono"
                                            label="Télefono"
                                            variant="standard"
                                            value={telefono}
                                            error={errors?.telefono}
                                            onKeyDown={handleKeyPressTelefono}
                                            onChange={handleSetTelefono}
                                            inputProps={{
                                                maxLength: 10,
                                                style: { textAlign: 'right', paddingBottom: '2px' }
                                            }}
                                        />
                                        {errors?.telefono && (
                                            <FormHelperText>{errors?.telefono}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </StepPanel>
                    </IGasForm>
                </DialogContent>

                <ActivateDialog open={modalActivate.isShowing} onClose={handleClose} postSubmit={handleClose} activarCliente={activarCliente} />

                <DialogActions>
                    <Button color="secondary" disableElevation disabled={clientesLoading.insert} onClick={handleClose}>Cancelar</Button>
                    {activeStep === 0 ? (
                        <Button color="secondary" disableElevation type="submit" form='form-update-dialog'>Siguiente</Button>
                    ) : activeStep < 3 ? (
                        <>
                            <Button color="secondary" disableElevation onClick={handleBack}>Atrás</Button>
                            <Button color="secondary" disableElevation type="submit" form='form-update-dialog'>Siguiente</Button>
                        </>
                    ) : (
                        <>
                            <Button color="secondary" disableElevation onClick={handleBack}>Atrás</Button>
                            <Button color="secondary" disableElevation disabled={clientesLoading.insert || catalagosLoading.getEstadoByCodigoPostal} type="submit" form='form-update-dialog'>Guardar</Button>
                        </>
                    )}
                </DialogActions>
            </Dialog >
            <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se agregó correctamente.' />
        </>
    )
}
