import { useDispatch, useSelector } from "react-redux";
import {
  FilterDrawer,
  InsertDialog,
  UpdateDialog,
  DeleteDialog,
  OptionTableRow,
} from "../../components/catalogos/seriesDocumentos";
import { useEffect, useState } from "react";
import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import {
  clearErrors,
  getAll,
  getEstado,
  setSerieDocumentoSelectedId,
  setSerieDocumento,
} from "../../store/catalogos/seriesDocumentos";
import {
  Catalogo,
  ColumnAlign,
  ColumnPriority,
  ColumnType,
  DangerMessage,
  WarningMessage,
} from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { listaTiposDoc } from "../../helpers/enums/tiposDocumento";

const ACTIVO = (value) =>
  value.activo ? (
    <CheckCircleOutline fontSize="small" color="primary" />
  ) : (
    <RadioButtonUnchecked fontSize="small" color="action" />
  );

const TABLE_DEFAULT = {
  columns: [
    {
      text: "Tipo de documento",
      name: "tipoDocumentoDescripcion",
      colAlign: ColumnAlign.Left,
      colType: ColumnType.String,
      Colpriority: ColumnPriority.High,
    },
    {
      text: "Serie",
      name: "serie",
      colAlign: ColumnAlign.Left,
      colType: ColumnType.String,
      Colpriority: ColumnPriority.High,
    },
    {
      text: "Descripción",
      name: "descripcion",
      colAlign: ColumnAlign.Left,
      colType: ColumnType.String,
      Colpriority: ColumnPriority.High,
    },
    {
      text: "Activo",
      name: "activo",
      colAlign: ColumnAlign.Center,
      icon: true,
      renderCell: ACTIVO,
      Colpriority: ColumnPriority.High,
    },
    {
      text: "",
      name: "action",
      colAlign: ColumnAlign.Center,
      options: true,
      icon: true,
    },
  ],
};

export function SeriesDocumentos() {
  const dispatch = useDispatch();
  const { loading, seriesDocumentos, error, filter } = useSelector(
    (state) => state.seriesDocumentos
  );

  const modalInsert = useModal(false);
  const modalEdit = useModal(false);
  const modalDelete = useModal(false);

  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const handleRefresh = () => {
    setSerieDocumento([]);
    if (filter.activo.state) dispatch(getEstado({ filter }));
    else dispatch(getAll());
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title="Series de documentos"
        table={TABLE_DEFAULT}
        data={seriesDocumentos}
        filter={filter}
        filterPanel={<FilterDrawer />}
        loading={loading.getAll}
        onRefresh={handleRefresh}
        showFilterData={true}
        onInsert={modalInsert.open}
        rowOptions={
          <OptionTableRow
            onSelect={(d) => {
              dispatch(setSerieDocumentoSelectedId(d.id));
              dispatch(setSerieDocumento(d));
            }}
            onEdit={modalEdit.open}
            onDelete={modalDelete.open}
          />
        }
      />

      <InsertDialog
        open={modalInsert.isShowing}
        onClose={modalInsert.close}
        postSubmit={handleRefresh}
        tiposDocumentos={listaTiposDoc}
      />
      <UpdateDialog
        open={modalEdit.isShowing}
        onClose={modalEdit.close}
        postSubmit={handleRefresh}
        tiposDocumentos={listaTiposDoc}
      />
      <DeleteDialog
        open={modalDelete.isShowing}
        onClose={modalDelete.close}
        postSubmit={handleRefresh}
      />
      <DangerMessage
        open={dangerMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
      <WarningMessage
        open={warningMsg.isShowing}
        onClose={handleCloseError}
        text={error?.message}
      />
    </>
  );
}
