import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAll, GetId, Insert, Update, SetMatriz, Delete } from "../../api/services/catalogos/direcciones";
import { hasErrorRedux } from "../../api/services/service";

export const getAll = createAsyncThunk("ClientesDirecciones/getAll", async (params, { rejectWithValue }) => {
    try {
        return await GetAll(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const getId = createAsyncThunk("ClientesDirecciones/selectId", async (params, { rejectWithValue }) => {
    try {
        return await GetId(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const insert = createAsyncThunk("ClientesDirecciones/insert", async (params, { rejectWithValue }) => {
    try {
        return await Insert(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const update = createAsyncThunk("ClientesDirecciones/update", async (params, { rejectWithValue }) => {
    try {
        return await Update(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const setMatriz = createAsyncThunk("ClientesDirecciones/setMatriz", async (params, { rejectWithValue }) => {
    try {
        return await SetMatriz(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const remove = createAsyncThunk("ClientesDirecciones/delete", async (params, { rejectWithValue }) => {
    try {
        return await Delete(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const initialState = {
    direcciones: [],
    direccion: {},
    direccionIdSelected: "",
    error: {},
    loading: {
        getAll: false,
        getId: false,
        insert: false,
        update: false,
        setMatriz: false,
        delete: false,
    },
};

const slice = createSlice({
    name: "direcciones",
    initialState,
    reducers: {
        setDireccion: (state, action) => {
            state.direccion = action.payload;
        },
        setDireccionIdSelected: (state, action) => {
            state.direccionIdSelected = action.payload;
        },
        clearErrors: (state) => {
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        //GetAll
        builder.addCase(getAll.pending, (state) => {
            state.loading.getAll = true;
        });
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getAll = false;
            state.direcciones = action.payload;
        });
        builder.addCase(getAll.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getAll = false;
            state.direcciones = [];
        });
        //GetId
        builder.addCase(getId.pending, (state) => {
            state.loading.getId = true;
        });
        builder.addCase(getId.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getId = false;
            state.direccion = action.payload;
        });
        builder.addCase(getId.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getId = false;
            state.direccion = {};
        });
        //Insert
        builder.addCase(insert.pending, (state) => {
            state.loading.insert = true;
        });
        builder.addCase(insert.fulfilled, (state, action) => {
            state.error = {};
            state.loading.insert = false;
        });
        builder.addCase(insert.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.insert = false;
        });
        //Update
        builder.addCase(update.pending, (state) => {
            state.loading.update = true;
        });
        builder.addCase(update.fulfilled, (state) => {
            state.error = {};
            state.loading.update = false;
        });
        builder.addCase(update.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.update = false;
        });
        //SetMatriz
        builder.addCase(setMatriz.pending, (state) => {
            state.loading.setMatriz = true;
        });
        builder.addCase(setMatriz.fulfilled, (state) => {
            state.error = {};
            state.loading.setMatriz = false;
        });
        builder.addCase(setMatriz.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.setMatriz = false;
        });
        //Remove
        builder.addCase(remove.pending, (state) => {
            state.loading.delete = true;
        });
        builder.addCase(remove.fulfilled, (state, action) => {
            state.error = {};
            state.loading.delete = false;
            state.direccion = action.payload;
        });
        builder.addCase(remove.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.delete = false;
        });
    },
});

export default slice.reducer;

export const { setDireccion, setDireccionIdSelected, clearErrors } = slice.actions;