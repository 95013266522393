import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetAll,
  GetById,
  GetEstado,
  Insert,
  Update,
  Delete,
} from "../../api/services/catalogos/seriesDocumentos";
import { hasErrorRedux } from "../../api/services/service";

export const getAll = createAsyncThunk(`/`, async (_, { rejectWithValue }) => {
  try {
    return await GetAll();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getById = createAsyncThunk(
  "SeriesDocumentos/selectId",
  async (params, { rejectWithValue }) => {
    try {
      return await GetById(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getEstado = createAsyncThunk(
  "SeriesDocumentos/selectEstado",
  async (params, { rejectWithValue }) => {
    try {
      return await GetEstado(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const insert = createAsyncThunk(
  "SeriesDocumentos/insert",
  async (params, { rejectWithValue }) => {
    try {
      return await Insert(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const update = createAsyncThunk(
  "SeriesDocumentos/update",
  async (params, { rejectWithValue }) => {
    try {
      return await Update(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const remove = createAsyncThunk(
  "SeriesDocumentos/delete",
  async (params, { rejectWithValue }) => {
    try {
      return await Delete(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const initialState = {
  seriesDocumentos: [],
  serieDocumento: {},
  serieDocumentoSelectedId: "",
  error: {},
  filter: {
    activo: { state: true, value: true, label: "Activo" },
  },
  loading: {
    getAll: false,
    getId: false,
    insert: false,
    update: false,
    delete: false,
  },
};

const slice = createSlice({
  name: "seriesDocumentos",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setSerieDocumento: (state, action) => {
      state.serieDocumento = action.payload;
    },
    setSerieDocumentoSelectedId: (state, action) => {
      state.serieDocumentoSelectedId = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },

  extraReducers: (builder) => {
    // GetALL
    builder.addCase(getAll.pending, (state) => {
      state.loading.getAll = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getAll = false;
      state.seriesDocumentos = action.payload;
    });
    builder.addCase(getAll.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getAll = false;
      state.seriesDocumentos = [];
    });

    // GetByID
    builder.addCase(getById.pending, (state) => {
      state.loading.getId = true;
    });
    builder.addCase(getById.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getId = false;
      state.serieDocumento = action.payload;
    });
    builder.addCase(getById.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getId = false;
      state.serieDocumento = {};
    });

    // GetESTADO
    builder.addCase(getEstado.pending, (state) => {
      state.loading.getEstado = true;
    });
    builder.addCase(getEstado.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getEstado = false;
      state.seriesDocumentos = action.payload;
    });
    builder.addCase(getEstado.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getEstado = false;
      state.seriesDocumentos = [];
    });

    // GetINSERT
    builder.addCase(insert.pending, (state) => {
      state.loading.insert = true;
    });
    builder.addCase(insert.fulfilled, (state, action) => {
      state.error = {};
      state.loading.insert = false;
    });
    builder.addCase(insert.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.insert = false;
    });

    // GetUPDATE
    builder.addCase(update.pending, (state) => {
      state.loading.update = true;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.error = {};
      state.loading.update = false;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.update = false;
    });

    // GetREMOVE
    builder.addCase(remove.pending, (state) => {
      state.loading.delete = true;
    });
    builder.addCase(remove.fulfilled, (state, action) => {
      state.error = {};
      state.loading.delete = false;
      state.serieDocumento = action.payload;
    });
    builder.addCase(remove.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.delete = false;
    });
  },
});

export default slice.reducer;

export const {
  setFilter,
  setSerieDocumento,
  setSerieDocumentoSelectedId,
  clearErrors,
} = slice.actions;
