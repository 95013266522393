import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import {
  IGasForm,
  IGasTextField,
  IGasSelectField,
  SuccessMessage,
} from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { insert } from "../../../store/catalogos/seriesDocumentos";

export function InsertDialog({ open, onClose, postSubmit, tiposDocumentos }) {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.seriesDocumentos);
  const [errors, setErrors] = useState({});
  const successMsg = useModal(false);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const onSubmit = (data) => {
    dispatch(insert(data))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  function initDialog() {
    if (open) {
      setErrors({});
    }
  }

  const assignErrors = () => {
    if (open & (error?.status === 400))
      setErrors(getModelErrors(error.message));
  };

  useEffect(initDialog, [open]);
  useEffect(assignErrors, [error]);

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        fullWidth
        disableEscapeKeyDown
      >
        <DialogTitle onClose={onClose}>Agregar serie de documento</DialogTitle>
        <DialogContent dividers={true}>
          <IGasForm id="form-update-dialog" onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <IGasSelectField
                  name="TipoDocumento"
                  label="Tipo de documento"
                  defaultValue={tiposDocumentos[0]?.id}
                  error={errors?.tipoDocumento}
                >
                  {tiposDocumentos.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {`${option.descripcion}`}
                    </MenuItem>
                  ))}
                </IGasSelectField>
              </Grid>
              <Grid item xs={6}>
                <IGasTextField
                  name="Serie"
                  label="Serie"
                  defaultValue=""
                  maxLength={25}
                  error={errors?.serie}
                />
              </Grid>
              <Grid item xs={12}>
                <IGasTextField
                  name="descripcion"
                  label="Descripción"
                  defaultValue=""
                  maxLength={50}
                  error={errors?.descripcion}
                />
              </Grid>
            </Grid>
          </IGasForm>
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            disableElevation
            disabled={loading.insert}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            color="secondary"
            disableElevation
            disabled={loading.insert}
            type="submit"
            form="form-update-dialog"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage
        open={successMsg.isShowing}
        onClose={successMsg.close}
        text="Se agregó correctamente."
      />
    </>
  );
}

export default InsertDialog;
