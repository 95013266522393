import { Button, ButtonGroup, FormControl, MenuItem, Select, Grid } from "@mui/material";
import { AccordionFilter } from "@paul-igas/igas-react-components";
import { getValues } from "@paul-igas/igas-react-hooks";
import { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../store/catalogos/despachadores";

export function FilterDrawer({ open, onClose }) {
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.usuarios);
  const [filters, setFilters] = useState(filter);
  const { handleSubmit, control, reset } = useForm();

  const onSubmit = (data) => {
    const array = Object.entries(filters);
    const values = array.map(([key, value]) => [key, { ...value, value: data[key] }]);
    const result = Object.fromEntries(values);

    dispatch(setFilter(result));
    onClose();
  };

  function handleCheckChange(event) {
    const name = event.target.name;
    const checked = event.target.checked;
    const values = filters[name];

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: { ...values, state: checked },
    }));
  }

  function initDrawer() {
    if (open) {
      reset(getValues(filter));
    }
  }

  useEffect(initDrawer, [open]);

  return (
    <div style={{ width: "100%" }}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        <AccordionFilter name='activo' label='Activo' filters={filters} onChange={handleCheckChange}>
          <FormControl fullWidth size='small'>
            <Controller
              name='activo'
              defaultValue={""}
              control={control}
              render={({ field }) => (
                <Select {...field} variant='standard'>
                  <MenuItem value={true}>Sí</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </AccordionFilter>
        <ButtonGroup
          disableElevation
          variant='contained'
          color='secondary'
          style={{
            marginTop: 16,
            marginBottom: 16,
            width: "100%",
          }}
          fullWidth
        >
          <Button onClick={onClose} color="secondary">Cancelar</Button>
          <Button type='submit' color ="secondary">Aplicar</Button>
        </ButtonGroup>
      </form>
    </div>
  );
}
