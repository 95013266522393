import axios from "axios";
import { getUser, logout } from "../authService";

const apiFormData = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default apiFormData;

export const hasDispatchError = (api) => {
  if (api.error) {
    throw new Error(api);
  }

  return api;
};

export const hasErrorRedux = (api) => {
  let STATUS = 401;
  let DATA = "";

  if (api.code === "ERR_NETWORK" || api.response.code === 404) {
    STATUS = 500;
    DATA = "Ocurrió un error de comunicación con el servicio";
  } else if (api.response.status === 400 && Boolean(api.response.data.errors)) {
    STATUS = 500;
    DATA = api.response.data.title;
  } else {
    STATUS = api.response.status;
    DATA = STATUS !== 500 ? decodeMsg(api.response.data) : "Ocurrió un error inesperado";
  }

  if (STATUS === 401) return logout();

  return {
    status: STATUS,
    message: DATA,
  };
};

apiFormData.interceptors.request.use(
  async (config) => {
    const user = await getUser();
    const token = user?.access_token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

function decodeMsg(msg) {
  if (Array.isArray(msg)) return msg;
  else if ((msg.byteLength ?? 0) > 0) {
    var enc = new TextDecoder("utf-8");
    return enc.decode(msg);
  } else return msg;
}
