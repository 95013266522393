import { useDispatch, useSelector } from "react-redux";
import {
    FilterDrawer, InsertDialog, UpdateDialog, DeleteDialog, OptionTableRow
} from "../../components/catalogos/formasPagos";
import { useEffect, useState } from "react";
import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import { clearErrors, getAll, getEstado, setformaPagIdSelected, setformaPago } from "../../store/catalogos/formasPagos";
import { Catalogo, ColumnAlign, ColumnPriority, ColumnType, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { GetAllFormasPagoSAT } from "../../api/services/consultas/catalogoSAT"

const ACTIVO = (value) =>
    value.activo ? (
        <CheckCircleOutline fontSize='small' color='primary' />
    ) : (
        <RadioButtonUnchecked fontSize='small' color='action' />
    );

const FORMAPAGO = (value) =>
    value.formaPagoSATClaveDescripcion == null
        ? ''
        : value.formaPagoSATClaveDescripcion

const TABLE_DEFAULT = {
    columns: [
        { text: "Descripción", name: "descripcion", colAlign: ColumnAlign.Left, colType: ColumnType.String, Colpriority: ColumnPriority.High },
        { text: "Forma de pago SAT", name: "formaPagoSATClaveDescripcion", colAlign: ColumnAlign.Left, colType: ColumnType.String, Colpriority: ColumnPriority.High, renderCell: FORMAPAGO },
        {
            text: "Activo",
            name: "activo",
            colAlign: ColumnAlign.Center,
            icon: true,
            renderCell: ACTIVO,
            Colpriority: ColumnPriority.High
        },
        { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
    ],
};

export function FormasPagos() {
    const dispatch = useDispatch();
    const { loading, formasPagos, error, filter } = useSelector((state) => state.formasPagos);

    const [formasPagoSAT, setFormasPagoSAT] = useState([]);
    const modalInsert = useModal(false);
    const modalEdit = useModal(false);
    const modalDelete = useModal(false);

    const dangerMsg = useModal(false);
    const warningMsg = useModal(false);

    const handleRefresh = () => {
        setformaPago([]);
        if (filter.activo.state)
            dispatch(getEstado({ filter }))
        else
            dispatch(getAll());
    };

    const handleError = () => {
        if (Boolean(error.status)) {
            error.status === 409 && warningMsg.open();
            error.status === 500 && dangerMsg.open();
        }
    };

    const handleCloseError = () => {
        dispatch(clearErrors());
        dangerMsg.close();
        warningMsg.close();
    };
    useEffect(handleError, [error]);

    useEffect(() => {
        const inicializaFormasPagoSAT = async () => {
            try {
                const listaformasPagoSAT = await GetAllFormasPagoSAT();
                setFormasPagoSAT(listaformasPagoSAT);
            } catch (error) {
                console.error('Error al inicializar formas de pago SAT:', error);
            }
        };

        inicializaFormasPagoSAT();
    }, []);

    return (
        <>
            <Catalogo
                title='Formas de pago'
                table={TABLE_DEFAULT}
                data={formasPagos}
                filter={filter}
                filterPanel={<FilterDrawer />}
                loading={loading.getAll}
                onRefresh={handleRefresh}
                showFilterData={true}
                onInsert={modalInsert.open}
                rowOptions={
                    <OptionTableRow
                        onSelect={(d) => {
                            dispatch(setformaPagIdSelected(d.id));
                            dispatch(setformaPago(d));
                        }}
                        onEdit={modalEdit.open}
                        onDelete={modalDelete.open}
                    />
                }
            />

            <InsertDialog open={modalInsert.isShowing} onClose={modalInsert.close} postSubmit={handleRefresh} formasPagoSAT={formasPagoSAT} />
            <UpdateDialog open={modalEdit.isShowing} onClose={modalEdit.close} postSubmit={handleRefresh} formasPagoSAT={formasPagoSAT} />
            <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />

            <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
            <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
        </>
    );
}
