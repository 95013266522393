import { Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { put } from "../../../store/catalogos/clientes";
import { useModal } from "@paul-igas/igas-react-hooks";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { boldText } from "../../../helpers/utils";

export function ActivateDialog({ open, onClose, postSubmit, activarCliente }) {
    const dispatch = useDispatch();
    const { loading, cliente } = useSelector((state) => state.clientes);
    const successMsg = useModal(false);

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") return;
        onClose();
    };

    const onSubmit = () => {
        dispatch(put(activarCliente.id))
            .then(hasDispatchError)
            .then(successMsg.open)
            .then(postSubmit)
            .catch(() => { });
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
                {!activarCliente.activo
                    ? (<DialogTitle>RFC con cliente inactivo</DialogTitle>)
                    : (<DialogTitle>RFC con cliente activo</DialogTitle>)}
                <DialogContent dividers>
                    {!activarCliente.activo ?
                        (<DialogContentText>
                            El RFC {boldText(activarCliente.rfc)} ya pertenece a un cliente que se encuentra inactivo. ¿Desea activarlo?
                        </DialogContentText>)
                        : (<DialogContentText>
                            El RFC {boldText(activarCliente.rfc)} ya se encuentra registrado en un cliente activo.
                        </DialogContentText>)}

                </DialogContent>
                <DialogActions>
                    {!activarCliente.activo ? (
                        <>
                            <Button color='secondary' onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button color='secondary' disabled={loading.delete} onClick={onSubmit}>
                                Activar
                            </Button>
                        </>)
                        : (<Button color='secondary' disabled={loading.delete} onClick={handleClose}>
                            Aceptar
                        </Button>)
                    }
                </DialogActions>
            </Dialog>
            <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Cliente activado correctamente.' />
        </>
    );
}
