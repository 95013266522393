import { FormHelperText, InputLabel, FormControl, Select, Stepper, Step, StepLabel, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, Checkbox, checked, ListItemText, MenuItem, Tabs, Tab, Box } from "@mui/material";
import { IGasForm, IGasSelectField, SuccessMessage, IGasTextField, IGasSwitchField, DetailInfo } from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { update, getId, setCliente } from "../../../store/catalogos/clientes";

function StepPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`step-panel-${index}`}
            aria-labelledby={`step-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export function UpdateDialog({ open, onClose, postSubmit, catalagosSAT, formasPagoAdmin }) {
    const dispatch = useDispatch();
    const { usosCFDISAT } = catalagosSAT
    const { loading, error, clienteIdSelected, cliente } = useSelector(state => state.clientes);
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({});
    const successMsg = useModal(false);

    const [selectedUsoCFDI, setSelectedUsoCFDI] = useState('0');
    const [regimenesSeleccionados, setRegimenesSeleccionados] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [checked, setChecked] = useState([0]);
    const [usoCFDIFiltrado, setUsoCFDIFiltrado] = useState([]);
    const [tipoPersonaState, setTipoPersonaState] = useState(0);
    const [usoYRegPublicoGeneral, setUsoYRegPublicoGeneral] = useState({});
    const [esPublico, setEsPublico] = useState(false);

    const emptyGuid = "00000000-0000-0000-0000-000000000000";

    const handleNext = () => {
        setErrorMessage("");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCargaRegimenes = () => {
        setSelectedUsoCFDI(cliente.usoCFDISATId);
        const tipoPersona = cliente.rfc?.length === 13 ? 0 : 1;
        setTipoPersonaState(tipoPersona)
        const UsosCFDIFiltrados = usosCFDISAT.filter(option => option.tipoPersona === tipoPersona || option.tipoPersona === 2)
        setUsoCFDIFiltrado(UsosCFDIFiltrados);

        const selectedOption = UsosCFDIFiltrados.find(option => option.id === cliente.usoCFDISATId);
        if (selectedOption) {
            setRegimenesSeleccionados(selectedOption.regimenesFiscalesSAT.sort((a, b) => a.clave.localeCompare(b.clave)));
            cliente.regimenesFiscalesSATIds.forEach(id => {
                setChecked(cliente.regimenesFiscalesSATIds)
            });
        } else {
            setRegimenesSeleccionados([]);
        }
    };

    const handleUsoCFDIChange = (event) => {
        setChecked([0]);
        const selectedId = event.target.value;
        setSelectedUsoCFDI(selectedId);

        const selectedOption = usoCFDIFiltrado.find(option => option.id === selectedId);
        const filtrados = selectedOption.regimenesFiscalesSAT.filter(
            (regimen) => regimen.tipoPersona === tipoPersonaState || regimen.tipoPersona === 2
        );
        if (selectedOption) {
            setRegimenesSeleccionados(filtrados.sort((a, b) => a.clave.localeCompare(b.clave)));
        } else {
            setRegimenesSeleccionados([]);
        }
    };

    const handleRFCChange = (rfc, cfdi) => {
        var tipoPersona = rfc === 13 ? 0 : 1;
        if (tipoPersona !== tipoPersonaState || esPublico) {
            setChecked([0]);
            setRegimenesSeleccionados([]);
            setSelectedUsoCFDI('0');
        }
        setTipoPersonaState(tipoPersona);
        var UsosCFDIFiltrados = usosCFDISAT.filter(option => option.tipoPersona === tipoPersona || option.tipoPersona === 2)
        setUsoCFDIFiltrado(UsosCFDIFiltrados);
        if (cfdi !== undefined && cfdi !== emptyGuid) {
            var selectedOption = UsosCFDIFiltrados.find(option => option.id === cfdi);
            var filtrados = selectedOption.regimenesFiscalesSAT.filter(
                (regimen) => regimen.tipoPersona === tipoPersona || regimen.tipoPersona === 2
            );
            if (selectedOption) {
                setRegimenesSeleccionados(filtrados.sort((a, b) => a.clave.localeCompare(b.clave)));
            } else {
                setRegimenesSeleccionados([]);
            }
        }
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return
        setErrorMessage("");
        setRegimenesSeleccionados([]);
        setActiveStep(0);
        setChecked([0]);
        onClose();
    }

    const handleSubmit = (data) => {
        setErrorMessage("");
        data.id = clienteIdSelected
        if (activeStep === 0) {
            const rfcFormatoRegex = data.RFC.length < 13
                ? /^([A-ZÑa-zñ]|\\&){3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Za-z0-9]{3}$/
                : /^([A-ZÑa-zñ]|\\&){4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Za-z0-9]{3}$/;
            const rfcMinusculasRegex = /[a-z]/;
            const emojisRegex = /([\u203C-\u3299]|[\uD83C-\uDBFF\uDC00-\uDFFF]+)/g;

            const RFCFormatoIncorrecto = !rfcFormatoRegex.test(data.RFC);
            if (RFCFormatoIncorrecto) {
                setErrorMessage((e) => ({
                    ...e,
                    RFC: "Formato de RFC incorrecto.",
                }));
                return;
            }
            const RFCMinusculas = rfcMinusculasRegex.test(data.RFC);
            if (RFCMinusculas) {
                setErrorMessage((e) => ({
                    ...e,
                    RFC: "RFC no debe contener letras minúsculas.",
                }));
                return;
            }
            if (data.RFC === 'XEXX010101000') {
                setErrorMessage((e) => ({
                    ...e,
                    RFC: "RFC no administrable.",
                }));
                return;
            }

            if (data.FormaPagoAdminId === '0') {
                setErrorMessage((e) => ({
                    ...e,
                    FormaPagoAdminId: "Forma pago es requerida.",
                }));
                return;
            }

            handleRFCChange(data.RFC.length, data.UsoCFDISATId)

            const razonSocialEmojis = emojisRegex.test(data.RazonSocial);
            if (razonSocialEmojis) {
                setErrorMessage((e) => ({
                    ...e,
                    RazonSocial: "Caracteres no alfanuméricos no permitidos.",
                }));
                return;
            }
            if (data.RazonSocial === '' || data.RazonSocial === null) {
                setErrorMessage((e) => ({
                    ...e,
                    RazonSocial: "Razón social es requerida.",
                }));
                return;
            }
            if (data.RazonSocial === '|') {
                setErrorMessage((e) => ({
                    ...e,
                    RazonSocial: "Formato incorrecto.",
                }));
                return;
            }
            const nombreComercialEmojis = emojisRegex.test(data.NombreComercial);
            if (nombreComercialEmojis) {
                setErrorMessage((e) => ({
                    ...e,
                    NombreComercial: "Caracteres no alfanuméricos no permitidos.",
                }));
                return;
            }
            if (data.NombreComercial === '' || data.NombreComercial === null) {
                setErrorMessage((e) => ({
                    ...e,
                    NombreComercial: "Nombre comercial es requerido.",
                }));
                return;
            }

            data.RFC === 'XAXX010101000' ? setEsPublico(true) : setEsPublico(false);

            handleNext();
            return;
        }
        if (!esPublico) {
            if (selectedUsoCFDI === emptyGuid || selectedUsoCFDI === null || selectedUsoCFDI === '0') {
                setErrorMessage((e) => ({
                    ...e,
                    UsoCFDISATId: "Uso de CDFI es requerido.",
                }));
                return;
            }

            const filteredChecked = checked.filter(item => item !== 0 && typeof item === 'string');
            if (filteredChecked.length === 0 || filteredChecked === null) {
                setErrorMessage((e) => ({
                    ...e,
                    RegimenesFiscalesSATIds: "Seleccione al menos un régimen fiscal.",
                }));
                return;
            }

            data.UsoCFDISATId = selectedUsoCFDI;
            data.RegimenesFiscalesSATIds = filteredChecked;
        } else {
            data.UsoCFDISATId = usoYRegPublicoGeneral.usoId;
            data.RegimenesFiscalesSATIds = [usoYRegPublicoGeneral.regId];
        }
        dispatch(update(data))
            .then(hasDispatchError)
            .then(onClose)
            .then(successMsg.open)
            .then(postSubmit)
            .catch(() => { });
    };

    function initDialog() {
        if (open) {
            setErrors({});
            const usoPubGen = usosCFDISAT.find(uso => uso.clave === "S01");
            const regPubGen = usoPubGen.regimenesFiscalesSAT.find(reg => reg.clave === "616");
            setUsoYRegPublicoGeneral({
                usoId: usoPubGen.id, usoDes: `${usoPubGen.clave} - ${usoPubGen.descripcion}`,
                regId: regPubGen.id, regDes: `${regPubGen.clave} - ${regPubGen.descripcion}`
            })

            setActiveStep(0);
            dispatch(getId(clienteIdSelected))
                .then(hasDispatchError)
                .catch(() => { });
        }
    }

    useEffect(() => {
        if (!loading.getId) {
            handleCargaRegimenes();
        }
    }, [loading.getId]);

    const assignErrors = () => {
        if (open & (error.status === 400)) setErrors(getModelErrors(error.message));
    };

    useEffect(initDialog, [open]);
    useEffect(assignErrors, [error]);
    const steps = ['Datos', 'Configuraciones'];

    return (
        <>
            <Dialog
                maxWidth='sm'
                open={open}
                onClose={handleClose}
                fullWidth
                disableEscapeKeyDown>
                <DialogTitle onClose={onClose}>Editar cliente</DialogTitle>
                <DialogContent dividers={true}>
                    {loading.getId && <>Cargando...</>}
                    {!loading.getId && (
                        <IGasForm id='form-update-dialog' onSubmit={handleSubmit}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <StepPanel value={activeStep} index={0}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <IGasTextField
                                            name="RFC"
                                            label='RFC'
                                            defaultValue={cliente?.rfc}
                                            error={errorMessage?.RFC}
                                            maxLength={13}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IGasSelectField
                                            name="FormaPagoAdminId"
                                            label='Forma de Pago Default'
                                            defaultValue={parseInt(cliente?.formaPagoAdminId)}
                                            error={errorMessage?.FormaPagoAdminId}
                                        >
                                            <MenuItem key={'0'} value='0' disabled>
                                                <em>Seleccione forma de pago</em>
                                            </MenuItem>
                                            {formasPagoAdmin.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {`${option.descripcion}`}
                                                </MenuItem>
                                            ))}
                                        </IGasSelectField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <IGasTextField
                                            name="RazonSocial"
                                            label='Razón Social'
                                            defaultValue={cliente?.razonSocial}
                                            error={errorMessage?.RazonSocial}
                                            maxLength={300}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <IGasTextField
                                            name="NombreComercial"
                                            label='Nombre Comercial'
                                            defaultValue={cliente?.nombreComercial}
                                            error={errorMessage?.NombreComercial}
                                            maxLength={100}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IGasSwitchField
                                            name='activo'
                                            defaultValue={cliente.activo}
                                            label='Activo'
                                        />
                                    </Grid>
                                </Grid>
                            </StepPanel>
                            <StepPanel value={activeStep} index={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        {esPublico ? (
                                            <DetailInfo
                                                title="Uso CFDI"
                                                description={usoYRegPublicoGeneral.usoDes}
                                            />)
                                            :
                                            (<>
                                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth error={!!errorMessage?.UsoCFDISATId}>
                                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                        Uso CFDI Default
                                                    </InputLabel>
                                                    <Select
                                                        name="UsoCFDISATId"
                                                        label={"Uso CFDI"}
                                                        defaultValue={cliente.usoCFDISATId}
                                                        value={selectedUsoCFDI}
                                                        error={!!errorMessage?.UsoCFDISATId}
                                                        fullWidth
                                                        onChange={handleUsoCFDIChange}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 300,
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value='0' disabled>
                                                            <em>Seleccione un uso CFDI</em>
                                                        </MenuItem>
                                                        {usoCFDIFiltrado?.map((option, index) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {`${option.clave} - ${option.descripcion.length < 57 ? option.descripcion : option.descripcion.substring(0, 57)}`}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errorMessage?.UsoCFDISATId && (
                                                        <FormHelperText>{errorMessage.UsoCFDISATId}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </>)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {esPublico ? (
                                            <DetailInfo
                                                title="Regimene fiscale SAT"
                                                description={usoYRegPublicoGeneral.regDes}
                                            />)
                                            :
                                            (<>
                                                {regimenesSeleccionados.length > 0 && (<InputLabel variant="standard" sx={{
                                                    fontSize: '0.7rem',
                                                    color: 'gray',
                                                }}>
                                                    Regímenes Fiscales Default
                                                </InputLabel>)}
                                                <FormControl error={!!errorMessage?.RegimenesFiscalesSATIds}>
                                                    <List dense={true}
                                                        name="RegimenesFiscalesSATIds"
                                                        label='Regimenes fiscales SAT'
                                                        sx={{
                                                            maxHeight: 200,
                                                            overflow: 'auto',
                                                        }}
                                                    >
                                                        {regimenesSeleccionados && regimenesSeleccionados.length > 0 ? (
                                                            regimenesSeleccionados.map((regimen) => (
                                                                <ListItem key={regimen.id} disablePadding>
                                                                    <ListItemButton role={undefined} onClick={handleToggle(regimen.id)} dense>
                                                                        <Checkbox
                                                                            edge="start"
                                                                            checked={checked.includes(regimen.id)}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                        />
                                                                        <ListItemText
                                                                            primary={`${regimen.clave} - ${regimen.descripcion}`}
                                                                        />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            ))
                                                        ) : (
                                                            <ListItem>
                                                            </ListItem>
                                                        )}
                                                    </List>
                                                    {errorMessage?.RegimenesFiscalesSATIds && (
                                                        <FormHelperText>{errorMessage.RegimenesFiscalesSATIds}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </>)}
                                    </Grid>
                                </Grid>
                            </StepPanel>
                        </IGasForm>
                    )}
                </DialogContent >
                <DialogActions>
                    <Button color="secondary" disableElevation disabled={loading.insert} onClick={handleClose}>Cancelar</Button>
                    {activeStep === 0 ? (
                        <Button color="secondary" disableElevation type="submit" form='form-update-dialog'>Siguiente</Button>
                    ) : (
                        <>
                            <Button color="secondary" disableElevation onClick={handleBack}>Atrás</Button>
                            <Button color="secondary" disableElevation disabled={loading.insert} type="submit" form='form-update-dialog'>Guardar</Button>
                        </>
                    )}
                </DialogActions>
            </Dialog >
            <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se agregó correctamente.' />
        </>
    )
}
