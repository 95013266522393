import { getSubscription } from "../../authService";
import api from "../service";

export async function GetAll(parametros) {
    let params = {
        suscripcion: await getSubscription(),
        roles: "Despachador",
        active: parametros.activo,
    };
    const res = await api.get("usuarioEstacion/servicios/SelectByRoleEstacion", { params });
    return res.data;
}

export async function GetId(id) {
    const params = { id: id };
    const res = await api.get("usuarioEstacion/servicios", { params });
    return res.data;
}

export async function Insert(params) {
    const res = await api.post("usuarioEstacion/servicios/registrarDespachador", params);
    return res.data;
}

export async function Update(params) {
    const res = await api.put("usuarioEstacion/servicios/updateDespachador", params);
    return res.data;
}

export async function Delete(id) {
    const params = { id: id };
    const res = await api.delete("usuarioEstacion/servicios/deleteDespachador", { params });
    return res.data;
}

export async function GetTimeZones() {
    const res = await api.get("usuarioEstacion/servicios/selectTimeZones");
    return res.data;
}

export async function SendConfirmEmail(id) {
    const params = { id: id };
    const res = await api.get("usuarioEstacion/servicios/enviarcorreo", { params });
    return res.data;
}

export default {
    GetAll,
    GetId,
    Insert,
    Update,
    Delete,
    GetTimeZones,
    SendConfirmEmail,
};
