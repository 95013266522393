import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, FormHelperText, InputLabel, FormControl, Select, TextField, LinearProgress, ToggleButtonGroup, ToggleButton, FormLabel } from "@mui/material";
import { IGasForm, IGasSelectField, IGasTextField, SuccessMessage } from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { insert } from "../../../store/catalogos/contactos";

export function InsertDialog({ open, onClose, postSubmit }) {
    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.contactos);
    const { clienteIdSelected } = useSelector(state => state.clientes);
    const [errors, setErrors] = useState({});
    const successMsg = useModal(false);
    const [telefono, setTelefono] = useState('');
    const [conCorreo, setConCorreo] = useState(false)
    const [correo, setCorreo] = useState('')

    const listaTiposDes = [
        {
            id: 0,
            descripcion: "Para"
        },
        {
            id: 1,
            descripcion: "CC"
        }
    ]

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return
        onClose();
    }

    const handleSubmit = (data) => {
        setErrors({});
        var request = {
            clienteId: clienteIdSelected,
            nombre: data.Nombre,
            telefono: telefono,
            puesto: data.Puesto,
            correoElectronico: correo,
            ...(correo.length > 0 ? { destinatario: data.Destinatario === 'n' ? null : data.Destinatario } : { destinatario: null }),
            ...(correo.length > 0 ? { recibirCorreosCFDI: true } : { recibirCorreosCFDI: false })
        };

        dispatch(insert(request))
            .then(hasDispatchError)
            .then(onClose)
            .then(successMsg.open)
            .then(postSubmit)
            .catch(() => { });
    };

    function initDialog() {
        if (open) {
            setErrors({});
            setConCorreo(false)
            setCorreo('')
            setTelefono('')
        }
    }

    const handleKeyPressTelefono = (event) => {
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
        const isNumber = /^\d$/.test(event.key);

        if (!isNumber && !allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    const handleSetTelefono = (event) => {
        setTelefono(event.target.value)
    }

    const handleChangeCorreo = (event) => {
        const correoElectronico = event.target.value;
        setCorreo(correoElectronico);
        if (correoElectronico.length > 0) {
            setConCorreo(true)
        }
        else {
            setConCorreo(false)
        }
    };

    const assignErrors = () => {
        if (open & (error?.status === 400)) setErrors(getModelErrors(error.message));
    };

    useEffect(initDialog, [open]);
    useEffect(assignErrors, [error]);

    return (
        <>
            <Dialog
                maxWidth='sm'
                open={open}
                onClose={handleClose}
                fullWidth
                disableEscapeKeyDown>
                <DialogTitle onClose={onClose}>Agregar contacto</DialogTitle>
                <DialogContent dividers={true}>
                    <IGasForm id='form-update-dialog' onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={6} >
                                <IGasTextField
                                    name="Nombre"
                                    label='Nombre'
                                    autoFocus
                                    defaultValue=''
                                    error={errors?.nombre}
                                    maxLength={300}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <FormControl variant="standard" fullWidth error={!!errors?.telefono}>
                                    <TextField
                                        name="Telefono"
                                        label="Télefono"
                                        variant="standard"
                                        value={telefono}
                                        error={errors?.telefono}
                                        onKeyDown={handleKeyPressTelefono}
                                        onChange={handleSetTelefono}
                                        inputProps={{
                                            maxLength: 10,
                                            style: { textAlign: 'right', paddingBottom: '2px' }
                                        }}
                                    />
                                    {errors?.telefono && (
                                        <FormHelperText>{errors?.telefono}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <IGasTextField
                                    name="Puesto"
                                    label='Puesto'
                                    defaultValue=''
                                    error={errors?.puesto}
                                    maxLength={50}
                                />
                            </Grid>
                            <Grid item xs={7} >
                                <FormControl variant="standard" fullWidth error={!!errors?.correoElectronico}>
                                    <TextField
                                        name="CorreoElectronico"
                                        label="Correo electrónico"
                                        variant="standard"
                                        value={correo}
                                        error={errors?.correoElectronico}
                                        onChange={handleChangeCorreo}
                                        inputProps={{
                                            maxLength: 320,
                                            style: { textAlign: 'left', paddingBottom: '2px' }
                                        }}
                                    />
                                    {errors?.correoElectronico && (
                                        <FormHelperText>{errors?.correoElectronico}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={5} >
                                <IGasSelectField
                                    name="Destinatario"
                                    label="Destinatario"
                                    defaultValue={'n'}
                                    error={errors?.destinatario}
                                    disabled={loading.getId || !conCorreo}
                                >
                                    <MenuItem key={'n'} value='n' disabled>
                                        <em>Seleccione destinatario</em>
                                    </MenuItem>
                                    {listaTiposDes.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {`${option.descripcion}`}
                                        </MenuItem>
                                    ))}
                                </IGasSelectField>
                            </Grid>
                        </Grid>
                    </IGasForm>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" disableElevation disabled={loading.insert} onClick={handleClose}>Cancelar</Button>
                    <Button color="secondary" disableElevation disabled={loading.insert} type="submit" form='form-update-dialog'>Guardar</Button>
                </DialogActions>
            </Dialog>
            <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se agregó correctamente.' />
        </>
    )
}
