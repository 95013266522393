import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from "@mui/material";
import { IGasForm, IGasSelectField, SuccessMessage, IGasTextField, IGasSwitchField } from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getId, update } from "../../../store/catalogos/formasPagos";


export function UpdateDialog({ open, onClose, postSubmit, formasPagoSAT }) {
  const dispatch = useDispatch();
  const { loading, formaPago, formaPagIdSelected, error } = useSelector((state) => state.formasPagos);
  const [errors, setErrors] = useState({});
  const successMsg = useModal(false);
  const emptyGuid = "00000000-0000-0000-0000-000000000000";

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick')
      return
    onClose();
  }

  const handleSubmit = (data) => {
    data.id = formaPagIdSelected
    dispatch(update(data))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => { });
  };

  const initDialog = () => {
    if (open) {
      setErrors({});
      dispatch(getId(formaPagIdSelected))
        .then(hasDispatchError)
        .catch(() => { });
    }
  }
  const assignErrors = () => {
    if (open & (error.status === 400)) setErrors(getModelErrors(error.message));
  };

  useEffect(initDialog, [open]);
  useEffect(assignErrors, [error]);

  return (
    <>
      <Dialog
        maxWidth='xs'
        open={open}
        onClose={handleClose}
        fullWidth
        disableEscapeKeyDown>
        <DialogTitle onClose={onClose}>Editar forma de pago</DialogTitle>
        <DialogContent dividers={true}>
          {loading.getId && <>Cargando...</>}
          {!loading.getId && (
            <IGasForm id='form-update-dialog' onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <IGasTextField
                    name="descripcion"
                    label="Descripción"
                    maxLength={100}
                    defaultValue={formaPago.descripcion}
                    error={errors?.descripcion}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IGasSelectField
                    name="formaPagoSATId"
                    label='Forma de Pago SAT'
                    defaultValue={formaPago?.formaPagoSATId ?? emptyGuid}
                    error={errors?.formaPagoSATId}
                  >
                    {formasPagoSAT
                      .sort((a, b) => a.clave.localeCompare(b.clave))
                      .map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {`${option.clave} - ${option.descripcion}`}
                        </MenuItem>
                      ))}
                  </IGasSelectField>
                </Grid>
                <Grid item xs={6}>
                  <IGasSwitchField
                    name='activo'
                    defaultValue={formaPago.activo}
                    label='Activo'
                  />
                </Grid>
              </Grid>
            </IGasForm>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" disableElevation disabled={loading.insert} onClick={handleClose}>Cancelar</Button>
          <Button color="secondary" disableElevation disabled={loading.insert} type="submit" form='form-update-dialog'>Guardar</Button>
        </DialogActions>
      </Dialog >
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se modificó correctamente.' />
    </>
  )
}
