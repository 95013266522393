import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Tooltip,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { SuccessMessage, IGasForm, IGasTextField, IGasSelectAutocompleteField } from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getTimeZones, insert } from "../../../store/catalogos/despachadores";
import { useForm } from "react-hook-form";
import { Info } from "@mui/icons-material";

export function InsertDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const successMsg = useModal(false);
  const { loading } = useSelector((state) => state.usuarios);
  const { stationTimeZone, timeZones } = useModalFetch(open);
  const { errors } = useModalErrors(open);
  const idForm = "form-despachador-insert-dialog";

  const { reset } = useForm();

  const FORM_DEFAULT = {
    correo: "",
    correoAdicional: "",
    nombreCompleto: "",
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const onSubmit = (data) => {
    dispatch(insert({ ...data, id: "", roles: ["Despachador"] }))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  function initDialog() {
    if (open) {
      reset(FORM_DEFAULT);
    }
  }

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='sm' scroll='paper'>
        {loading.insert && <LinearProgress />}
        <DialogTitle onClose={onClose}>Agregar despachador</DialogTitle>

        <DialogContent dividers>
          <IGasForm id={idForm} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <IGasTextField
                  label='Nombre Completo'
                  name='nombreCompleto'
                  defaultValue={FORM_DEFAULT.nombreCompleto}
                  disabled={loading.insert}
                  error={errors?.nombreCompleto}
                  maxLength={70}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {!stationTimeZone && <Skeleton />}
                {stationTimeZone && (
                  <IGasSelectAutocompleteField
                    label='Zona horaria'
                    name='zonaHoraria'
                    defaultValue={stationTimeZone}
                    error={errors?.zonaHoraria}
                    options={timeZones}
                    getOptionLabel={(option) => `${option?.name} (${option?.utc})`}
                    disabled={loading.insert}
                  />
                )}
              </Grid>
            </Grid>
          </IGasForm>
        </DialogContent>

        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.insert} onClick={onClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={loading.insert} type='submit' form={idForm}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage
        open={successMsg.isShowing}
        onClose={successMsg.close}
        text='Se guardó el despachador correctamente.'
      />
    </>
  );
}

const useModalFetch = (open) => {
  const dispatch = useDispatch();
  const [timeZones, setTimeZones] = useState([]);
  const { profile } = useSelector((state) => state.aplicacion);
  const init = async () => {
    if (open) {
      const [res] = await Promise.all([dispatch(getTimeZones())]);
      setTimeZones(res.payload);
    }
  };

  useEffect(() => {
    init();
  }, [open, dispatch]);

  return {
    stationTimeZone: timeZones.filter((tz) => tz.name === profile.timeZonestacion)[0] ?? null,
    timeZones,
  };
};

const useModalErrors = (open) => {
  const [errors, setErrors] = useState({});
  const { error } = useSelector((state) => state.usuarios);

  const assignErrors = () => {
    if (error.status === 400) setErrors(getModelErrors(error.message));
  };
  const resetErrors = () => {
    setErrors({});
  };

  useEffect(assignErrors, [error]);
  useEffect(resetErrors, [open]);

  return { errors };
};

export default InsertDialog;
