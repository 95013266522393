import api from "../service";

export async function GetAllFormasPagoSAT() {
    const res = await api.get('catalogosat/FormasPagoSAT');
    return res.data;
}

export async function GetUsosCFDISAT() {
    const res = await api.get('catalogosat/UsosCFDISAT');
    return res.data;
}

export async function GetPaisesSAT() {
    const res = await api.get('catalogosat/PaisesSAT');
    return res.data;
}

export async function GetEstadoByCodigoPostal(params) {
    const res = await api.get(`catalogosat/CodigosPostalesSAT/bycodigopostal/${params}`);
    return res.data;
}