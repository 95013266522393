import api from "../service";

const controller = 'Cliente';

export async function GetAll() {
    const res = await api.get(controller);
  return res.data;
}

export async function GetId(id) {
    const res = await api.get(`${controller}/byId/${id}`);
  return res.data;
}

export async function GetExist(params) {
  const res = await api.get(`${controller}/existsbyrfc/${params}`);
return res.data;
}

export async function GetEstado(params) {
  var estado = params.filter.activo.value;
    const res = await api.get(`${controller}/${estado}`);
  return res.data;
}

export async function Insert(params) {
    const res = await api.post(controller, params);
  return res.data;
}

export async function Update(params) {
    const res = await api.put(controller, params);
  return res.data;
}

export async function Put(params) {
  const res = await api.put(`${controller}/setActivo/${params}`);
return res.data;
}

export async function Delete(id) {
    const res = await api.delete(`${controller}/${id}`);
  return res.data;
}

export default {
  GetAll,
  GetId,
  GetEstado,
  Insert,
  Put,
  Update
};
