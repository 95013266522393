import { useState } from "react";
import { Backspace, Edit, MoreVert } from "@mui/icons-material";
import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

export function OptionTableRow({ data, onEdit, onDelete, onSelect }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleSelect = () => onSelect(data);

  const handleEdit = () => {
    handleClose();
    handleSelect();
    onEdit();
  };

  const handleDelete = () => {
    handleClose();
    handleSelect();
    onDelete();
  };

  return (
    <>
      <Button
        size="small"
        disableElevation
        style={{ minWidth: 0 }}
        onClick={handleOpen}
      >
        <MoreVert fontSize="small" color="action" />
      </Button>

      {Boolean(anchorEl) && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem dense onClick={handleEdit}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Editar</Typography>
          </MenuItem>

          <MenuItem dense onClick={handleDelete}>
            <ListItemIcon>
              <Backspace fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Eliminar</Typography>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

export default OptionTableRow;
