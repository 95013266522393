import {
    Button,
    ButtonGroup,
    FormControl,
    MenuItem,
    Select,
  } from "@mui/material";
  import { AccordionFilter } from "@paul-igas/igas-react-components";
  import { getValues } from "@paul-igas/igas-react-hooks";
  import { useEffect, useState } from "react";
  import { Controller, useForm } from "react-hook-form";
  import { useDispatch, useSelector } from "react-redux";
  import { setFilter } from "../../../store/catalogos/contactos";
  
  const menuItems = [
    <MenuItem key={1} value={true}>
      Si
    </MenuItem>,
    <MenuItem key={2} value={false}>
      No
    </MenuItem>,
  ];
  
  export function FilterDrawer({ open, onClose }) {
    const { filter } = useSelector((state) => state.contactos);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(filter);
    const { handleSubmit, control, reset } = useForm();
  
    function initDrawer() {
      if (open) {
        reset(getValues(filter));
      }
    }
  
    const onSubmit = (data) => {
      const array = Object.entries(filters);
      const values = array.map(([key, value]) => [
        key,
        { ...value, value: data[key] },
      ]);
      const result = Object.fromEntries(values);
      dispatch(setFilter(result));
      onClose();
    };
  
    function handleCheckChange(event) {
      const name = event.target.name;
      const checked = event.target.checked;
      const values = filters[name];
  
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: { ...values, state: checked },
      }));
    }
  
    useEffect(initDrawer, [open]);
  
    return (
      <div style={{ width: "100%" }}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <AccordionFilter
            name="activo"
            label="Activo"
            filters={filters}
            onChange={handleCheckChange}
          >
            <FormControl fullWidth size="small">
              <Controller
                name="activo"
                defaultValue={filters.activo.value}
                control={control}
                render={({ field }) => (
                  <Select {...field} variant="standard">
                    {menuItems}
                  </Select>
                )}
              />
            </FormControl>
          </AccordionFilter>
          <ButtonGroup
            disableElevation
            variant="contained"
            color="secondary"
            style={{
              marginTop: 16,
              marginBottom: 16,
              width: "100%",
            }}
            fullWidth
          >
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="submit">Aplicar</Button>
          </ButtonGroup>
        </form>
      </div>
    );
  }
  