import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAll, GetId, GetEstado, GetExist, Insert, Update, Put, Delete } from "../../api/services/catalogos/clientes";
import { hasErrorRedux } from "../../api/services/service";

export const getAll = createAsyncThunk("Clientes/getAll", async (_, { rejectWithValue }) => {
    try {
        return await GetAll();
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const getId = createAsyncThunk("Clientes/selectId", async (params, { rejectWithValue }) => {
    try {
        return await GetId(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const getExist = createAsyncThunk("Clientes/existRFC", async (params, { rejectWithValue }) => {
    try {
        return await GetExist(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const getEstado = createAsyncThunk("Clientes/selectEstado", async (params, { rejectWithValue }) => {
    try {
        return await GetEstado(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const insert = createAsyncThunk("Clientes/insert", async (params, { rejectWithValue }) => {
    try {
        return await Insert(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const put = createAsyncThunk("Clientes/put", async (params, { rejectWithValue }) => {
    try {
        return await Put(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const update = createAsyncThunk("Clientes/update", async (params, { rejectWithValue }) => {
    try {
        return await Update(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const remove = createAsyncThunk("Clientes/delete", async (params, { rejectWithValue }) => {
    try {
        return await Delete(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const initialState = {
    clientes: [],
    cliente: {},
    clienteIdSelected: "",
    error: {},
    filter: {
        activo: { state: true, value: true, label: "Activo" },
    },
    loading: {
        getAll: false,
        getId: false,
        getEstado: false,
        getExist: false,
        insert: false,
        update: false,
        put: false,
        delete: false,
    },
};

const slice = createSlice({
    name: "clientes",
    initialState,
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setCliente: (state, action) => {
            state.cliente = action.payload;
        },
        setClienteIdSelected: (state, action) => {
            state.clienteIdSelected = action.payload;
        },
        clearErrors: (state) => {
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        //getAll
        builder.addCase(getAll.pending, (state) => {
            state.loading.getAll = true;
        });
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getAll = false;
            state.clientes = action.payload;
        });
        builder.addCase(getAll.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getAll = false;
            state.clientes = [];
        });
        //getId
        builder.addCase(getId.pending, (state) => {
            state.loading.getId = true;
        });
        builder.addCase(getId.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getId = false;
            state.cliente = action.payload;
        });
        builder.addCase(getId.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getId = false;
            state.cliente = {};
        });
        //getExist
        builder.addCase(getExist.pending, (state) => {
            state.loading.getExist = true;
        });
        builder.addCase(getExist.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getExist = false;
            state.cliente = action.payload;
        });
        builder.addCase(getExist.rejected, (state, action) => {
            state.loading.getExist = false;
            state.cliente = {};
        });
        //getEstado
        builder.addCase(getEstado.pending, (state) => {
            state.loading.getEstado = true;
        });
        builder.addCase(getEstado.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getEstado = false;
            state.clientes = action.payload;
        });
        builder.addCase(getEstado.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getEstado = false;
            state.clientes = [];
        });
        //insert
        builder.addCase(insert.pending, (state) => {
            state.loading.insert = true;
        });
        builder.addCase(insert.fulfilled, (state, action) => {
            state.error = {};
            state.loading.insert = false;
        });
        builder.addCase(insert.rejected, (state, action) => {
            let detalle = action.payload;
            if (detalle.status === 400) {
                const matchingItems = action.payload.message.filter(item => 
                    /^Contacto\.(Nombre|CorreoElectronico|Telefono|Puesto)/.test(item.propertyName)
                );
                if (matchingItems.length > 0) {
                    const newMessages = matchingItems.map(item => ({
                        propertyName: item.propertyName.split('.')[1].toLowerCase(),
                        errorMessage: item.errorMessage
                    }));
                    detalle = {
                        ...detalle,
                        message: [
                            ...action.payload.message,
                            ...newMessages
                        ]
                    };
                }
            }
            state.error = detalle;
            state.loading.insert = false;
        });
        //update
        builder.addCase(update.pending, (state) => {
            state.loading.update = true;
        });
        builder.addCase(update.fulfilled, (state) => {
            state.error = {};
            state.loading.update = false;
        });
        builder.addCase(update.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.update = false;
        });
        //put
        builder.addCase(put.pending, (state) => {
            state.loading.put = true;
        });
        builder.addCase(put.fulfilled, (state) => {
            state.error = {};
            state.loading.put = false;
        });
        builder.addCase(put.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.put = false;
        });
        //remove
        builder.addCase(remove.pending, (state) => {
            state.loading.delete = true;
        });
        builder.addCase(remove.fulfilled, (state, action) => {
            state.error = {};
            state.loading.delete = false;
            state.cliente = action.payload;
        });
        builder.addCase(remove.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.delete = false;
        });
    },
});

export default slice.reducer;

export const { setFilter, setCliente, setClienteIdSelected, clearErrors } = slice.actions;