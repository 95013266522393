import { useDispatch, useSelector } from "react-redux";
import { useCatalagosSAT } from "../../hooks";
import {
    FilterDrawer, DeleteDialog, OptionTableRow, InsertDialog, UpdateDialog, DetailDialog
} from "../../components/catalogos/clientes";
import { useEffect, useState } from "react";
import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import { clearErrors, getAll, getEstado, setClienteIdSelected, setCliente } from "../../store/catalogos/clientes";
import { Catalogo, ColumnAlign, ColumnPriority, ColumnType, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { GetAll } from "../../api/services/catalogos/formasPagos";
import { useNavigate } from "react-router-dom";

const ACTIVO = (value) =>
    value.activo ? (
        <CheckCircleOutline fontSize='small' color='primary' />
    ) : (
        <RadioButtonUnchecked fontSize='small' color='action' />
    );

const TABLE_DEFAULT = {
    columns: [
        { text: "RFC", name: "rfc", colAlign: ColumnAlign.Left, colType: ColumnType.String, Colpriority: ColumnPriority.High },
        { text: "Razón Social", name: "razonSocial", colAlign: ColumnAlign.Left, colType: ColumnType.String, Colpriority: ColumnPriority.High, orderDefault: true },
        { text: "Nombre comercial", name: "nombreComercial", colAlign: ColumnAlign.Left, colType: ColumnType.String, Colpriority: ColumnPriority.High },
        {
            text: "Activo",
            name: "activo",
            colAlign: ColumnAlign.Center,
            icon: true,
            renderCell: ACTIVO,
            Colpriority: ColumnPriority.High
        },
        { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
    ],
};

export function Clientes() {
    const dispatch = useDispatch();
    const catalagosSAT = useCatalagosSAT();
    const { loading, clientes, error, filter } = useSelector((state) => state.clientes);
    const [formasPagoAdmin, setFormasPagoAdmin] = useState([]);
    const modalInsert = useModal(false);
    const modalEdit = useModal(false);
    const modalDetail = useModal(false);
    const modalDelete = useModal(false);
    const navigate = useNavigate();

    const dangerMsg = useModal(false);
    const warningMsg = useModal(false);

    const handleRefresh = () => {
        setCliente([]);
        if (filter.activo.state)
            dispatch(getEstado({ filter }))
        else
            dispatch(getAll());
    };

    const handleError = () => {
        if (Boolean(error.status)) {
            error.status === 409 && warningMsg.open();
            error.status === 500 && dangerMsg.open();
        }
    };

    const handleCloseError = () => {
        dispatch(clearErrors());
        dangerMsg.close();
        warningMsg.close();
    };

    useEffect(handleError, [error]);

    useEffect(() => {
        const inicializaUsosCFDIFormasPagoSAT = async () => {
            try {
                const listaFormasPagoAdmin = await GetAll();
                setFormasPagoAdmin(listaFormasPagoAdmin);
            } catch (error) {
                console.error('Error al inicializar datos del catálogo SAT:', error);
            }
        };
        inicializaUsosCFDIFormasPagoSAT();
    }, []);

    const handleNavDir = () => {
        navigate("/catalogos/direcciones");
    };

    const handleNavContac = () => {
        navigate("/catalogos/contactos");
    };

    return (
        <>
            <Catalogo
                title='Clientes'
                table={TABLE_DEFAULT}
                data={clientes}
                filter={filter}
                filterPanel={<FilterDrawer />}
                loading={loading.getAll || loading.getEstado}
                onRefresh={handleRefresh}
                showFilterData={true}
                onInsert={modalInsert.open}
                rowOptions={
                    <OptionTableRow
                        onSelect={(d) => {
                            dispatch(setClienteIdSelected(d.id));
                            dispatch(setCliente(d));
                        }}
                        onDirecciones={handleNavDir}
                        onContactos={handleNavContac}
                        onEdit={modalEdit.open}
                        onDelete={modalDelete.open}
                        onDetail={modalDetail.open}
                    />
                }
            />

            <InsertDialog open={modalInsert.isShowing} onClose={modalInsert.close} postSubmit={handleRefresh} onRefresh={handleRefresh} formasPagoAdmin={formasPagoAdmin} catalagosSAT={catalagosSAT} />
            <DetailDialog open={modalDetail.isShowing} onClose={modalDetail.close} postSubmit={handleRefresh} formasPagoAdmin={formasPagoAdmin} catalagosSAT={catalagosSAT} />
            <UpdateDialog open={modalEdit.isShowing} onClose={modalEdit.close} postSubmit={handleRefresh} formasPagoAdmin={formasPagoAdmin} catalagosSAT={catalagosSAT} />
            <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />

            <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
            <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
        </>
    );
}