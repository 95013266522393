import { useState } from "react";
import { Backspace, Edit, MoreVert, Pageview, HolidayVillage, ContactMail  } from "@mui/icons-material";
import { Button, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";

export function OptionTableRow({ data, onDelete, onSelect, onEdit, onDetail, onDirecciones, onContactos }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleSelect = () => onSelect(data);

  const handleDirecciones = () => {
    handleClose();
    handleSelect();
    onDirecciones();
  };

  const handleContactos = () => {
    handleClose();
    handleSelect();
    onContactos();
  };

  const handleEdit = () => {
    handleClose();
    handleSelect();
    onEdit();
  };

  const handleDelete = () => {
    handleClose();
    handleSelect();
    onDelete();
  };

  const handleDetail = () => {
    handleClose();
    handleSelect();
    onDetail();
  };

  return (
    <>
      <Button size='small' disableElevation style={{ minWidth: 0 }} onClick={handleOpen}>
        <MoreVert fontSize='small' color='action' />
      </Button>

      {Boolean(anchorEl) && (
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem dense onClick={handleDetail}>
            <ListItemIcon>
              <Pageview fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit'>Ver Detalle</Typography>
          </MenuItem>
          <MenuItem dense onClick={handleEdit}>
            <ListItemIcon>
              <Edit fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit'>Editar</Typography>
          </MenuItem>
          <MenuItem dense onClick={handleDirecciones}>
            <ListItemIcon>
              <HolidayVillage fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit'>Direcciones</Typography>
          </MenuItem>
          <MenuItem dense onClick={handleContactos}>
            <ListItemIcon>
              <ContactMail fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit'>Contactos</Typography>
          </MenuItem>
          <MenuItem dense onClick={handleDelete}>
            <ListItemIcon>
              <Backspace fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit'>Eliminar</Typography>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

export default OptionTableRow;
