import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import {
    Get,
    GetAll,
    GetLink,
    Insert,
    Update,
    Delete
} from "../../api/services/catalogos/plantillasImpresiones";
import { GetTipoDocumentoIndex } from "../../helpers/enums/tiposDocumento";
import { getOnlyActiveValues } from "@paul-igas/igas-react-hooks";

export const get = createAsyncThunk("plantillas/get", async (params, { rejectWithValue }) => {
    try {
        return await Get(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const getLink = createAsyncThunk("plantillas/getLink", async (params, { rejectWithValue }) => {
    try {
        return await GetLink(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const getAll = createAsyncThunk("plantillas/getAll", async (params, { rejectWithValue }) => {
    try {
        const tipo = GetTipoDocumentoIndex(params.tipo.value);
        const _tipo = params.tipo;

        let nuevoTipo = { ..._tipo, value: parseInt(tipo) };
        let _params = { ...params, tipodocumento: nuevoTipo };
        return await GetAll(getOnlyActiveValues(_params));

    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const insert = createAsyncThunk("plantillas/insert", async (params, { rejectWithValue }) => {
    try {
        return await Insert(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const update = createAsyncThunk("plantillas/update", async (params, { rejectWithValue }) => {
    try {
        return await Update(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const remove = createAsyncThunk("plantillas/remove", async (params, { rejectWithValue }) => {
    try {
        return await Delete(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const initialState = {
    plantilla: {},
    plantillas: [],
    plantillaIdSelected: {},
    error: {},
    filter: {
        activo: { state: true, value: true, label: "Activo" },
        tipo: { state: false, value: "Factura de contado", label: "Tipo de documento" },
    },
    loading: {
        get: false,
        getAll: false,
        getLink: false,
        insert: false,
        update: false,
        delete: false,
    },
};

const slice = createSlice({
    name: "plantillas",
    initialState,
    reducers: {
        setPlantillaIdSelected: (state, action) => {
            state.plantillaIdSelected = action.payload;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        clearErrors: (state) => {
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        //Get
        builder.addCase(get.pending, (state) => {
            state.loading.get = true;
        });
        builder.addCase(get.fulfilled, (state, action) => {
            state.error = {};
            state.plantilla = action.payload;
            state.loading.get = false;
        });
        builder.addCase(get.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.get = false;
        });
        //GetAll
        builder.addCase(getAll.pending, (state) => {
            state.loading.getAll = true;
        });
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.error = {};
            state.plantillas = action.payload;
            state.loading.getAll = false;
        });
        builder.addCase(getAll.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getAll = false;
        });
        //GetLink
        builder.addCase(getLink.pending, (state) => {
            state.loading.getLink = true;
        });
        builder.addCase(getLink.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getLink = false;
        });
        builder.addCase(getLink.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getLink = false;
        });
        //Insert
        builder.addCase(insert.pending, (state) => {
            state.loading.insert = true;
        });
        builder.addCase(insert.fulfilled, (state, action) => {
            state.error = {};
            state.loading.insert = false;
        });
        builder.addCase(insert.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.insert = false;
        });
        //Update
        builder.addCase(update.pending, (state) => {
            state.loading.update = true;
        });
        builder.addCase(update.fulfilled, (state) => {
            state.error = {};
            state.loading.update = false;
        });
        builder.addCase(update.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.update = false;
        });
        //Remove
        builder.addCase(remove.pending, (state) => {
            state.loading.delete = true;
        });
        builder.addCase(remove.fulfilled, (state) => {
            state.error = {};
            state.loading.delete = false;
        });
        builder.addCase(remove.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.delete = false;
        });
    },
});

export default slice.reducer;

export const { setPlantillaIdSelected, setFilter, clearErrors } = slice.actions;
