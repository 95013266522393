import { Backspace, Edit, Email, ExpandMore } from "@mui/icons-material";
import {
  Alert,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Skeleton,
  Switch,
} from "@mui/material";
import { Grid, List, ListItemIcon, ListItemText, ListItemButton, Paper, Popper } from "@mui/material";
import { DetailInfo, SuccessMessage } from "@paul-igas/igas-react-components";
import { useModal, usePopper } from "@paul-igas/igas-react-hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError, roles } from "../../../api/services/service";
import { getId } from "../../../store/catalogos/despachadores";
import { createTheme, styled } from "@mui/material/styles";
import UpdateDialog from "./updateDialog";
import { DeleteDialog } from "./deleteDialog";
import { SendConfirmEmail } from "../../../api/services/catalogos/despachadores";
const theme = createTheme();

const Menu = styled(Popper)(() => ({
  zIndex: theme.zIndex.modal + 1,
}));

export function DetailDialog({ open, onClose, postSubmit, visibleOptions = true }) {
  const dispatch = useDispatch();
  const { loading, userIdSelected, user } = useSelector((state) => state.usuarios);
  const modalEdit = useModal(false);
  const modalDelete = useModal(false);
  const loadingDialog = loading.getId && !modalEdit.isShowing && !modalDelete.isShowing;

  function initDialog() {
    if (open) {
      dispatch(getId(userIdSelected)).then(hasDispatchError).catch(onClose);
    }
  }
  function handleDelete() {
    onClose();
    postSubmit();
  }
  function handleEdit() {
    initDialog();
    postSubmit();
  }

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    onClose();
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth={true} maxWidth={"sm"} scroll={"paper"}>
        <DialogTitle onClose={onClose}>Detalle de despachador</DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <DetailInfo
                title='Nombre completo'
                description={user.nombreCompleto ?? "Error"}
                loading={loadingDialog}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DetailInfo
                title='Zona horaria'
                description={user.zonaHoraria ? `${user.zonaHoraria?.name} (${user.zonaHoraria?.utc})` : "Error"}
                loading={loadingDialog}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {loadingDialog && <Skeleton />}
              {!loadingDialog && (
                <FormControlLabel
                  disabled
                  control={<Switch size='small' checked={user?.activo} />}
                  label={<span style={{ fontSize: "0.85rem" }}>Activo</span>}
                  sx={{ ["& .MuiFormControlLabel-label.Mui-disabled"]: { color: "black" } }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {visibleOptions && <Options loading={loadingDialog} onDelete={modalDelete.open} onEdit={modalEdit.open} />}
          <Button onClick={onClose} color='secondary' disableElevation>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
      <UpdateDialog open={modalEdit.isShowing} onClose={modalEdit.close} postSubmit={handleEdit} />
      <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleDelete} />
    </>
  );
}

function Options({ loading, onDelete, onEdit }) {
  const { user } = useSelector((state) => state.usuarios);
  const menu = usePopper(false);
  const sendEmailMsg = useModal(false);
  function handleDelete() {
    menu.close();
    onDelete();
  }
  function handleEdit() {
    menu.close();
    onEdit();
  }
  function handleSendEmail() {
    menu.close();
    const res = SendConfirmEmail(user.id);
    if (res) sendEmailMsg.open();
  }

  return (
    <>
      <Button onClick={menu.open} disabled={loading} endIcon={<ExpandMore />}>
        Opciones
      </Button>
      {Boolean(menu.anchorEl) && (
        <Menu open={menu.isShowing} anchorEl={menu.anchorEl} placement={"bottom-end"}>
          <ClickAwayListener onClickAway={menu.close}>
            <Paper>
              <List dense={true}>             
                <ListItemButton onClick={handleEdit}>
                  <ListItemIcon>
                    <Edit fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Editar' />
                </ListItemButton>
                <ListItemButton onClick={handleDelete}>
                  <ListItemIcon>
                    <Backspace fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Eliminar' />
                </ListItemButton>
              </List>
            </Paper>
          </ClickAwayListener>
        </Menu>
      )}
      <SuccessMessage
        open={sendEmailMsg.isShowing}
        onClose={sendEmailMsg.close}
        text='Se ha enviado el correo correctamente.'
      />
    </>
  );
}
