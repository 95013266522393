import api from "../service";
import { getStation } from "../../authService";

const _controller = "SerieDocumento";

export async function GetAll() {
  const res = await api.get(_controller);
  return res.data;
}

export async function GetById(id) {
  const res = await api.get(`${_controller}/byId/${id}`);
  return res.data;
}

export async function GetEstado(params) {
  var estado = params.filter.activo.value;
  const res = await api.get(`${_controller}/${estado}`);
  return res.data;
}

export async function Insert(params) {
  const res = await api.post(_controller, params);
  return res.data;
}

export async function Update(params) {
  params.idEstacion = await getStation();
  const res = await api.put(_controller, params);
  return res.data;
}

export async function Delete(id) {
  const res = await api.delete(`${_controller}/${id}`);
  return res.data;
}

export default {
  GetAll,
  GetById,
  GetEstado,
  Insert,
  Update,
  Delete,
};
