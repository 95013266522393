import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import { GetPaisesSAT, GetUsosCFDISAT, GetEstadoByCodigoPostal } from "../../api/services/consultas/catalogoSAT";

export const getPaisesSAT = createAsyncThunk("catalogosSAT/paisesSAT", async (_, { rejectWithValue }) => {
    try {
        return await GetPaisesSAT();
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const getUsosCFDISAT = createAsyncThunk("catalogosSAT/usosCFDISAT", async (_, { rejectWithValue }) => {
    try {
        return await GetUsosCFDISAT();
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const getEstadoByCodigoPostal = createAsyncThunk("catalogosSAT/EstadoSATByCodigoPostal", async (params, { rejectWithValue }) => {
    try {
        return await GetEstadoByCodigoPostal(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const initialState = {
    error: {},
    loading: {
        getEstadoByCodigoPostal: false
    },
};

const slice = createSlice({
    name: "catalogosSAT",
    initialState,
    reducers: {
        clearErrors: (state) => {
            state.error = {};
        },
    },

    extraReducers: (builder) => {
        // GetEstadoByCodigoPostal
        builder.addCase(getEstadoByCodigoPostal.pending, (state) => {
            state.loading.getEstadoByCodigoPostal = true;
        });
        builder.addCase(getEstadoByCodigoPostal.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getEstadoByCodigoPostal = false;
        });
        builder.addCase(getEstadoByCodigoPostal.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getEstadoByCodigoPostal = false;
        });
    },
});

export default slice.reducer;
export const { clearErrors } = slice.actions;
