import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    LinearProgress,
    MenuItem,
    styled,
} from "@mui/material";
import {
    IGasForm,
    IGasTextField,
    IGasSelectField,
    IGasSwitchField,
} from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { update, getLink } from "../../../store/catalogos/plantillasImpresiones";
import { listaTiposDoc } from "../../../helpers/enums/tiposDocumento";
import { CloudDownload, CloudUpload } from "@mui/icons-material";
import { boldText } from "../../../helpers/utils";

export function UpdateDialog({ open, onClose, postSubmit }) {
    const dispatch = useDispatch();
    const { loading, error, plantilla, plantillaIdSelected } = useSelector((state) => state.plantillas);
    const [errors, setErrors] = useState({});
    const [file, setFile] = useState([]);
    const [fileName, setFileName] = useState('');
    const [mensajeAdjunto, setMensajeAdjunto] = useState(false);


    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        onClose();
    };

    const onSubmit = (data) => {
        setErrors('')
        const formData = new FormData();
        formData.append('id', plantillaIdSelected);
        formData.append('tipoDocumento', data.TipoDocumento);
        formData.append('descripcion', data.Descripcion === undefined ? '' : data.Descripcion);
        formData.append('activo', data.Activo);
        if (file && file.length > 0)
            formData.append('plantilla', file[0]);
        dispatch(update(formData))
            .then(hasDispatchError)
            .then(onClose)
            .then(postSubmit)
            .catch(() => { });
    };

    const handleChange = (event) => {
        setErrors("")
        setMensajeAdjunto(false)
        if (event.target.files[0].type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            || event.target.files[0].size > 1048576) {
            setErrors((e) => ({
                dropzoneArea: "Solo se permiten archivos de extensión .docx con un peso máximo de 1 MB.",
            }));
        }
        else {
            setMensajeAdjunto(true)
            setFile(event.target.files)

            const maxLength = 20;
            if (event.target.files[0].name) {
                const fileName = event.target.files[0].name;
                const shortName = fileName.length > maxLength
                    ? fileName.slice(0, -11) + "..." + "(.docx)"
                    : fileName;
                setFileName(shortName)
            }
        }
    }

    const handleDescarga = () => {
        dispatch(getLink(plantillaIdSelected))
            .then(hasDispatchError)
            .then((response) => {
                if (response?.payload) {
                    window.open(response.payload, "_blank");
                }
            })
            .catch(() => { });
    }

    const assignErrors = () => {
        if (open & (error?.status === 400))
            setErrors(getModelErrors(error.message));
    };

    function initDialog() {
        if (open) {
            setErrors("");
            setFile([])
            setMensajeAdjunto(false)
        }
    }

    useEffect(initDialog, [open]);
    useEffect(assignErrors, [error]);

    return (
        <>
            <Dialog
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                fullWidth
                disableEscapeKeyDown
            >
                {loading.getLink && <LinearProgress />}
                <DialogTitle onClose={onClose}>Editar plantilla de impresión</DialogTitle>
                <DialogContent dividers={true}>
                    {loading.get && <>Cargando...</>}
                    {!loading.get && (
                        <IGasForm id="form-update-dialog" onSubmit={onSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <IGasSelectField
                                        name="TipoDocumento"
                                        label="Tipo de documento"
                                        defaultValue={plantilla.tipoDocumento}
                                        error={errors?.tipoDocumento}
                                        disabled={loading.update || loading.getLink}
                                    >
                                        {listaTiposDoc.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {`${option.descripcion}`}
                                            </MenuItem>
                                        ))}
                                    </IGasSelectField>
                                </Grid>
                                <Grid item xs={7}>
                                    <IGasTextField
                                        name="Descripcion"
                                        label="Descripción"
                                        defaultValue={plantilla.descripcion}
                                        maxLength={20}
                                        error={errors?.descripcion}
                                        disabled={loading.update || loading.getLink}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl id="form-update2" variant="standard" style={{ width: '100%' }}>
                                        <Button
                                            color="secondary"
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<CloudDownload />}
                                            onClick={handleDescarga}
                                        >
                                            Descargar
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl id="form-update2" variant="standard" error={!!errors?.dropzoneArea} style={{ width: '100%' }}>
                                        <Button
                                            color="secondary"
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<CloudUpload />}
                                            fullWidth
                                        >
                                            Actualizar
                                            <VisuallyHiddenInput
                                                type="file"
                                                onChange={handleChange}
                                                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            />
                                        </Button>
                                        {errors?.dropzoneArea && (
                                            <FormHelperText>{errors.dropzoneArea}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} paddingRight={"10px"}>
                                    <IGasSwitchField
                                        name="Activo"
                                        defaultValue={plantilla.activo}
                                        label="Activo"
                                        disabled={loading.update || loading.getLink}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="right" paddingTop={"20px"}>
                                <Grid item xs={12}>
                                    {mensajeAdjunto && (<Alert severity='success' height='10px'>
                                        <AlertTitle>
                                            Archivo {boldText(fileName)} cargado con éxito
                                        </AlertTitle>
                                        La nueva plantilla se actualizará al hacer clic en el botón {boldText("Guardar")}.
                                    </Alert>)}
                                </Grid>
                            </Grid>
                        </IGasForm>)}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        disableElevation
                        disabled={loading.update || loading.getLink}
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="secondary"
                        disableElevation
                        disabled={loading.update || loading.getLink || loading.get}
                        type="submit"
                        form="form-update-dialog"
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    );
}

export default UpdateDialog;
