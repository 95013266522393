import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getById, remove } from "../../../store/catalogos/seriesDocumentos";
import { useModal } from "@paul-igas/igas-react-hooks";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { boldText } from "../../../helpers/utils";

export function DeleteDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading, serieDocumento, serieDocumentoSelectedId } = useSelector(
    (state) => state.seriesDocumentos
  );
  const buttonsLoading = loading.delete || loading.getById;
  const { handleSubmit } = useForm();
  const successMsg = useModal(false);

  const onSubmit = () => {
    dispatch(remove(serieDocumentoSelectedId))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const init = () => {
    if (open === true) {
      dispatch(getById(serieDocumentoSelectedId))
        .then(hasDispatchError)
        .catch(onClose);
    }
  };

  useEffect(init, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xs">
        <DialogTitle>Eliminar serie de documento</DialogTitle>
        <DialogContent>
          {loading.getId && <>Cargando...</>}
          {!loading.getId && (
            <DialogContentText>
              ¿Desea eliminar la serie {boldText(serieDocumento?.serie)} para{" "}
              {boldText(serieDocumento?.tipoDocumentoDescripcion?.toLowerCase())}?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" disabled={buttonsLoading} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            color="secondary"
            disabled={buttonsLoading}
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage
        open={successMsg.isShowing}
        onClose={successMsg.close}
        text="Se eliminó correctamente."
      />
    </>
  );
}
