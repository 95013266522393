import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from "@mui/material";
import { IGasForm, IGasTextField, IGasSelectField, SuccessMessage } from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { insert } from "../../../store/catalogos/formasPagos";

export function InsertDialog({ open, onClose, postSubmit, formasPagoSAT }) {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(state => state.formasPagos);
  const [errors, setErrors] = useState({});
  const successMsg = useModal(false);

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick')
      return
    onClose();
  }

  const handleSubmit = (data) => {
    dispatch(insert(data))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => { });
  };

  function initDialog() {
    if (open) {
      setErrors({});
    }
  }

  const assignErrors = () => {
    if (open & (error.status === 400)) setErrors(getModelErrors(error.message));
  };

  useEffect(initDialog, [open]);
  useEffect(assignErrors, [error]);

  return (
    <>
      <Dialog
        maxWidth='xs'
        open={open}
        onClose={handleClose}
        fullWidth
        disableEscapeKeyDown>
        <DialogTitle onClose={onClose}>Agregar forma de pago</DialogTitle>
        <DialogContent dividers={true}>
          <IGasForm id='form-update-dialog' onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <IGasTextField
                  name="descripcion"
                  label='Descripción'
                  defaultValue=''
                  error={errors?.descripcion}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={6}>
                <IGasSelectField
                  name="FormaPagoSATId"
                  label='Forma de Pago SAT'
                  defaultValue={formasPagoSAT[0]?.id}
                >
                  {formasPagoSAT.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {`${option.clave} - ${option.descripcion}`}
                    </MenuItem>
                  ))}
                </IGasSelectField>
              </Grid>
            </Grid>
          </IGasForm>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" disableElevation disabled={loading.insert} onClick={handleClose}>Cancelar</Button>
          <Button color="secondary" disableElevation disabled={loading.insert} type="submit" form='form-update-dialog'>Guardar</Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se agregó correctamente.' />
    </>
  )
}
