import { Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { remove, getId } from "../../../store/catalogos/contactos";
import { useModal } from "@paul-igas/igas-react-hooks";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { useEffect } from "react";
import { boldText } from "../../../helpers/utils";

export function DeleteDialog({ open, onClose, postSubmit }) {
    const dispatch = useDispatch();
    const { loading, contactoIdSelected, contacto } = useSelector((state) => state.contactos);
    const successMsg = useModal(false);

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") return;
        onClose();
    };

    const onSubmit = () => {
        dispatch(remove(contactoIdSelected))
            .then(hasDispatchError)
            .then(onClose)
            .then(successMsg.open)
            .then(postSubmit)
            .catch(() => { });
    };

    const initDialog = () => {
        if (open) {
            dispatch(getId(contactoIdSelected))
                .catch(() => { });
        }
    }

    useEffect(initDialog, [open]);

    return (
        <>
            <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
                <DialogTitle>Eliminar contacto</DialogTitle>
                <DialogContent dividers>
                    {loading.getId && <>Cargando...</>}
                    {!loading.getId && (
                        <DialogContentText>
                            ¿Desea eliminar el contacto {boldText(contacto.nombre)}?
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button color='secondary' disabled={loading.delete} onClick={onSubmit}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se eliminó correctamente.' />
        </>
    );
}
