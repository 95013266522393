import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAll, GetId, GetEstado, Insert, Update, Delete } from "../../api/services/catalogos/formasPagos";
import { hasErrorRedux } from "../../api/services/service";

export const getAll = createAsyncThunk("FormasPagos/getAll", async (_, { rejectWithValue }) => {
    try {
        return await GetAll();
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const getId = createAsyncThunk("FormasPagos/selectId", async (params, { rejectWithValue }) => {
    try {
        return await GetId(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const getEstado = createAsyncThunk("FormasPagos/selectEstado", async (params, { rejectWithValue }) => {
    try {
        return await GetEstado(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const insert = createAsyncThunk("FormasPagos/insert", async (params, { rejectWithValue }) => {
    try {
        return await Insert(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const update = createAsyncThunk("FormasPagos/update", async (params, { rejectWithValue }) => {
    try {
        return await Update(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const remove = createAsyncThunk("FormasPagos/delete", async (params, { rejectWithValue }) => {
    try {
        return await Delete(params);
    } catch (error) {
        return rejectWithValue(hasErrorRedux(error));
    }
});

export const initialState = {
    formasPagos: [],
    formaPago: {},
    formaPagIdSelected: "",
    error: {},
    filter: {
        activo: { state: true, value: true, label: "Activo" },
    },
    loading: {
        getAll: false,
        getId: false,
        insert: false,
        update: false,
        delete: false,
    },
};

const slice = createSlice({
    name: "formasPagos",
    initialState,
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setformaPago: (state, action) => {
            state.formaPago = action.payload;
        },
        setformaPagIdSelected: (state, action) => {
            state.formaPagIdSelected = action.payload;
        },
        clearErrors: (state) => {
            state.error = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAll.pending, (state) => {
            state.loading.getAll = true;
        });
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getAll = false;
            state.formasPagos = action.payload;
        });
        builder.addCase(getAll.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getAll = false;
            state.formasPagos = [];
        });
        builder.addCase(getId.pending, (state) => {
            state.loading.getId = true;
        });
        builder.addCase(getId.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getId = false;
            state.formaPago = action.payload;
        });
        builder.addCase(getId.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getId = false;
            state.formaPago = {};
        });
        builder.addCase(getEstado.pending, (state) => {
            state.loading.getEstado = true;
        });
        builder.addCase(getEstado.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getEstado = false;
            state.formasPagos = action.payload;
        });
        builder.addCase(getEstado.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getEstado = false;
            state.formasPagos = [];
        });
        builder.addCase(insert.pending, (state) => {
            state.loading.insert = true;
        });
        builder.addCase(insert.fulfilled, (state, action) => {
            state.error = {};
            state.loading.insert = false;
        });
        builder.addCase(insert.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.insert = false;
        });
        builder.addCase(update.pending, (state) => {
            state.loading.update = true;
        });
        builder.addCase(update.fulfilled, (state) => {
            state.error = {};
            state.loading.update = false;
        });
        builder.addCase(update.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.update = false;
        });
        builder.addCase(remove.pending, (state) => {
            state.loading.delete = true;
        });
        builder.addCase(remove.fulfilled, (state, action) => {
            state.error = {};
            state.loading.delete = false;
            state.formaPago = action.payload;
        });
        builder.addCase(remove.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.delete = false;
            state.formaPago = {};
        });
    },
});

export default slice.reducer;

export const { setFilter, setformaPago, setformaPagIdSelected, clearErrors } = slice.actions;