import { WebStorageStateStore } from "oidc-client";

export const SETTINGS_OPENID = {
    authority: process.env.REACT_APP_AUTH_URL,
    client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
    client_secret: process.env.REACT_APP_IDENTITY_CLIENT_SECRET,
    scope: process.env.REACT_APP_SCOPE,
    response_type: process.env.REACT_APP_RESPONSE_TYPE,
    post_logout_redirect_uri: window.location.origin + process.env.PUBLIC_URL + "/signout-oidc",
    redirect_uri: window.location.origin + process.env.PUBLIC_URL + "/signin-oidc",
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: true,
    userStore: new WebStorageStateStore({ store: localStorage }),
    gateway_id: process.env.REACT_APP_GATEWAY_ID,
};

export const Environment = () => {
    let entorno = process.env.REACT_APP_IGAS_ENVIRONMENT ?? "";

    if (entorno === "DEV" || entorno === "QA") {
        return false;
    }

    return true;
};
