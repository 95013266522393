import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { remove } from "../../../store/catalogos/plantillasImpresiones";
import { useModal } from "@paul-igas/igas-react-hooks";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { boldText } from "../../../helpers/utils";

export function DeleteDialog({ open, onClose, postSubmit }) {
    const dispatch = useDispatch();
    const { loading, plantillaIdSelected, plantilla } = useSelector(
        (state) => state.plantillas
    );
    const successMsg = useModal(false);

    const handleSubmit = () => {
        dispatch(remove(plantillaIdSelected))
            .then(hasDispatchError)
            .then(onClose)
            .then(successMsg.open)
            .then(postSubmit)
            .catch(() => { });
    };

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        onClose();
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xs">
                <DialogTitle>Eliminar plantilla de impresión</DialogTitle>
                <DialogContent>
                    {loading.get && <>Cargando...</>}
                    {!loading.get  && (
                        <DialogContentText>
                            ¿Desea eliminar la plantilla {boldText(plantilla?.descripcion)}?
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" disabled={loading.delete} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="secondary"
                        disabled={loading.get || loading.delete}
                        autoFocus
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <SuccessMessage
                open={successMsg.isShowing}
                onClose={successMsg.close}
                text="Se eliminó correctamente."
            />
        </>
    );
}
