import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthRoute from "./components/authRoute";
import { NotFound, SigninOidc, SignOutOidc } from "./pages/auth";
import {
  Combustibles, Estaciones, ProductosDiversos,
  TiposPagos, Clientes, Direcciones,
  Usuarios, Cajeros, Despachadores,
  FormasPagos, SeriesDocumentos, PlantillasImpresiones,
  Contactos
} from "./pages/catalogo";
import { BitacoraCon } from "./pages/consultas";
import DashBoard from "./pages/dashboard";
import { LecturasIniciales } from "./pages/operaciones";
import { ConfiguracionEstacion } from "./pages/herramientas";

function App() {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route path='/' element={<DashBoard />} />
        {/* Catálogos */}
        <Route path='/catalogos/*'>
          <Route path='combustibles' element={<Combustibles />} />
          <Route path='estaciones' element={<Estaciones />} />
          <Route path='clientes' element={<Clientes />} />
          <Route path='direcciones' element={<Direcciones />} />
          <Route path='contactos' element={<Contactos />} />
          <Route path='usuarios' element={<Usuarios />} />
          <Route path='cajeros' element={<Cajeros />} />
          <Route path='despachadores' element={<Despachadores />} />
          <Route path='productosdiversos' element={<ProductosDiversos />} />
          <Route path='tiposPagos' element={<TiposPagos />} />
          <Route path='formasPagos' element={<FormasPagos />} />
          <Route path="seriesDocumentos" element={<SeriesDocumentos />} />
          <Route path="plantillasImpresiones" element={<PlantillasImpresiones />} />
        </Route>

        {/* {Operaciones} */}
        <Route path='/operaciones/*'>
          <Route path='lecturasIniciales' element={<LecturasIniciales />} />
        </Route>

        {/* Consultas */}
        <Route path='/consultas/*'>
          <Route path='bitacora' element={<BitacoraCon />} />
        </Route>

        {/* Herramientas */}
        <Route path='/herramientas/*'>
          <Route path='configuracionEstacion' element={<ConfiguracionEstacion />} />
        </Route>

        {/* En caso de que no exista la ruta, se carga esta página */}
        <Route path='*' element={<NotFound />} />
      </Route>

      <Route path='/signin-oidc' element={<SigninOidc />} />
      <Route path='/signout-oidc' element={<SignOutOidc />} />
    </Routes>
  );
}

export default App;
