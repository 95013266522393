import api from "../service";
import apiFormData from "../serviceFormData";

const _controller = "plantillaimpresion"

export async function GetAll(params) {
    const res = await api.get(_controller, { params });
    return res.data;
}

export async function Get(params) {
    const res = await api.get(`${_controller}/${params}`);
    return res.data;
}

export async function GetLink(params) {
    const res = await api.get(`${_controller}/sasUri/${params}`);
    return res.data;
}

export async function Insert(params) {
    const res = await apiFormData.post(_controller, params);
    return res.data;
}

export async function Update(params) {
    const res = await apiFormData.put(_controller, params);
    return res.data;
}

export async function Delete(params) {
    const res = await api.delete(`${_controller}/${params}`);
    return res.data;
}

export default {
    Get,
    GetAll,
    GetLink,
    Insert,
    Update,
    Delete,
};
