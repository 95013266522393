import { useEffect } from "react";
import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import { Catalogo, ColumnAlign, ColumnPriority, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAll } from "../../store/catalogos/productosDiversos";
import { Environment } from "../../utils/authConst";

const CELL_ACTIVE = (value) =>
  value.activo ? (
    <CheckCircleOutline fontSize='small' color='primary' />
  ) : (
    <RadioButtonUnchecked fontSize='small' color='action' />
  );
const TABLE_DEFAULT = {
  columns: [
    {
      text: "Descripción",
      name: "descripcion",
      colAlign: ColumnAlign.Left,
      orderDefault: true,
    },
    {
      text: "Unidad",
      name: "unidad",
      colPriority: ColumnPriority.Normal,
      colAlign: ColumnAlign.Right.Left,
    },
    ...(Environment()
      ? []
      : [
          {
            text: "Unidad SAT",
            name: "unidadSATClaveDescripcion",
            colAlign: ColumnAlign.Left,
          },
        ]),
    ...(Environment()
      ? []
      : [
          {
            text: "Producto o Servicio SAT",
            name: "productoServicioSATClaveDescripcion",
            colAlign: ColumnAlign.Left,
          },
        ]),
    ...(Environment()
      ? []
      : [
          {
            text: "Objeto Impuesto SAT",
            name: "objetoImpuestoSATClaveDescripcion",
            colAlign: ColumnAlign.Left,
          },
        ]),
    {
      text: "Activo",
      name: "activo",
      colAlign: ColumnAlign.Center,
      icon: true,
      renderCell: CELL_ACTIVE,
    },
  ],
};

export function ProductosDiversos() {
  const dispatch = useDispatch();
  const { loading, productosDiversos, error } = useSelector((state) => state.productosDiversos);
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const handleRefresh = (_) => dispatch(getAll());
  const handleError = () => {
    if (Boolean(error.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };
  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title='Productos diversos'
        table={TABLE_DEFAULT}
        data={productosDiversos}
        loading={loading.getAll}
        onRefresh={handleRefresh}
      />

      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error.message} />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error.message} />
    </>
  );
}
