import { Backspace, Edit, Email, MoreVert, Pageview } from "@mui/icons-material";
import { Button, ClickAwayListener, List, ListItemIcon, ListItemText, ListItemButton, Paper, Popper } from "@mui/material";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { useModal, usePopper } from "@paul-igas/igas-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { SendConfirmEmail } from "../../../api/services/catalogos/despachadores";
import { setUserIdSelected } from "../../../store/catalogos/despachadores";

export function OptionTableRow(props) {
    const {
        data = {},
        onEdit = () => { },
        onDetail = () => { },
        onDelete = () => { },
    } = props;

    const dispatch = useDispatch();
    const menu = usePopper(false);
    const sendEmailMsg = useModal(false);
    const { profile } = useSelector(state => state.aplicacion);

    const selectUser = (id) => dispatch(setUserIdSelected(id));
    const handleEdit = () => {
        menu.close();
        selectUser(data.id);
        onEdit();
    };
    const handleDetail = () => {
        menu.close();
        selectUser(data.id);
        onDetail();
    }
    const handleDelete = () => {
        menu.close();
        selectUser(data.id);
        onDelete();
    }

    const handleSendEmail = () => {
        menu.close();
        const res = SendConfirmEmail(data.id);
        if (res) sendEmailMsg.open();
    }

    const self = profile.sub === data.id ?? '';

    return (
        <>
            <Button size="small" disableElevation style={{ minWidth: 0 }} onClick={menu.open} >
                <MoreVert fontSize="small" color="action" />
            </Button>
            {Boolean(menu.anchorEl) &&
                <Popper open={menu.isShowing} anchorEl={menu.anchorEl} placement={'bottom-end'}>
                    <ClickAwayListener onClickAway={menu.close}>
                        <Paper>
                            <List dense={true}>
                                <ListItemButton onClick={handleDetail}>
                                    <ListItemIcon>
                                        <Pageview fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Ver detalle" />
                                </ListItemButton>                               
                                <ListItemButton onClick={handleEdit}>
                                    <ListItemIcon>
                                        <Edit fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Editar" />
                                </ListItemButton>
                                <ListItemButton onClick={handleDelete}>
                                    <ListItemIcon>
                                        <Backspace fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Eliminar" />
                                </ListItemButton>
                            </List>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            }
            <SuccessMessage open={sendEmailMsg.isShowing} onClose={sendEmailMsg.close} text='Se envió el correo correctamente.' />
        </>
    )
}
export default OptionTableRow;