import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetConfigurations } from "../api/services/herramientas/configuraciones";
import { hasErrorRedux } from "../api/services/service";
import { Environment } from "../utils/authConst";

export const getConfigurations = createAsyncThunk(
  "configuracionEstacion/configuraciones",
  async (params, { rejectWithValue }) => {
    try {
      return await GetConfigurations(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const STATES = {
  LOADING: 0,
  AUTHORIZED: 200,
  FORBIDDEN: 403,
};

export const ALERTMESSAGETYPES = {
  SUCCESS: 1,
  WARNING: 2,
  DANGER: 3,
};

const initialState = {
  profile: {},
  routes: [
    {
      text: "Inicio",
      route: "/",
      childrens: [],
      iconName: "Home",
      nested: 16,
    },
    {
      text: "Catálogos",
      iconName: "Summarize",
      childrens: [
        {
          text: "Estaciones",
          route: "/catalogos/estaciones",
          childrens: [],
          nested: 32,
        },
        {
          text: "Usuarios",
          route: "/catalogos/usuarios",
          childrens: [],
          roles: ["Administrador"],
          nested: 32,
        },
        ...(Environment()
          ? []
          : [
              {
                text: "Clientes",
                route: "/catalogos/clientes",
                childrens: [],
                roles: ["Administrador"],
                nested: 32,
              },
            ]),
        {
          text: "Cajeros",
          route: "/catalogos/cajeros",
          childrens: [],
          roles: ["Administrador", "Supervisor"],
          nested: 32,
        },
        {
          text: "Despachadores",
          route: "/catalogos/despachadores",
          childrens: [],
          roles: ["Administrador", "Supervisor"],
          nested: 32,
        },
        {
          text: "Combustibles",
          route: "/catalogos/combustibles",
          childrens: [],
          roles: ["Administrador"],
          nested: 32,
        },
        {
          text: "Productos diversos",
          route: "/catalogos/productosDiversos",
          childrens: [],
          roles: ["Administrador"],
          nested: 32,
        },
        {
          text: "Tipos de pago",
          route: "/catalogos/tipospagos",
          childrens: [],
          roles: ["Administrador"],
          nested: 32,
        },
        ...(Environment()
          ? []
          : [
              {
                text: "Formas de pago",
                route: "/catalogos/formasPagos",
                childrens: [],
                roles: ["Administrador"],
                nested: 32,
              },
            ]),
        ...(Environment()
          ? []
          : [
              {
                text: "Series de documentos",
                route: "/catalogos/seriesDocumentos",
                childrens: [],
                roles: ["Administrador"],
                nested: 32,
              },
            ]),
        ...(Environment()
          ? []
          : [
              {
                text: "Plantillas de impresión",
                route: "/catalogos/plantillasImpresiones",
                childrens: [],
                roles: ["Administrador"],
                nested: 32,
              },
            ]),
      ],
    },
    {
      text: "Operaciones",
      iconName: "Work",
      childrens: [
        {
          text: "Lecturas iniciales",
          route: "/operaciones/lecturasIniciales",
          roles: ["Administrador"],
          childrens: [],
          nested: 32,
        },
      ],
    },
    {
      text: "Consultas",
      iconName: "Visibility",
      childrens: [
        {
          text: "Bitácora",
          route: "/consultas/bitacora",
          childrens: [],
          nested: 32,
        },
      ],
    },
    {
      text: "Herramientas",
      iconName: "Build",
      childrens: [
        {
          text: "Configuración estación",
          route: "/herramientas/configuracionEstacion",
          roles: ["Administrador"],
          childrens: [],
          nested: 32,
        },
      ],
    },
    {
      route: "/catalogos/direcciones",
      roles: ["Administrador"],
    },
    {
      route: "/catalogos/contactos",
      roles: ["Administrador"],
    },
  ],
  state: STATES.LOADING,
  stations: [],
  error: {},
  backdrop: false,
  configurations: [],
  progresBarApp: false,
  alertMessage: {},
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setStations: (state, action) => {
      state.stations = action.payload;
    },
    setBackdrop: (state, action) => {
      state.backdrop = action.payload;
    },
    setConfigurations: (state, action) => {
      state.configurations = action.payload;
    },
    setProgressBarApp: (state, action) => {
      state.progresBarApp = action.payload;
    },
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConfigurations.pending, (state) => {
      state.loading.getConfigurations = true;
    });
    builder.addCase(getConfigurations.fulfilled, (state, action) => {
      state.error = {};
      state.configurations = action.payload;
      state.loading.getConfigurations = false;
    });
    builder.addCase(getConfigurations.rejected, (state, action) => {
      state.error = action.payload;
      state.configurations = [];
      state.loading.getConfigurations = false;
    });
  },
});

export default slice.reducer;

export const { setProfile, setState, setStations, setBackdrop, setConfigurations, setProgressBarApp, setAlertMessage } =
  slice.actions;
