import { getPaisesSAT, getUsosCFDISAT } from "../store/consultas/catalogosSAT";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export function useCatalagosSAT() {
    const dispatch = useDispatch();
    const [paisesSAT, setPaisesSAT] = useState([]);
    const [usosCFDISAT, setUsosCFDISAT] = useState([]);

    const initCatalogos = () => {
        dispatch(getUsosCFDISAT())
            .then(({ payload }) => {
                setUsosCFDISAT(payload)
            })
            .catch(() => { });

        dispatch(getPaisesSAT())
            .then(({ payload }) => {
                setPaisesSAT(payload)
            })
            .catch(() => { });
    }
    useEffect(initCatalogos, [])

    return {
        paisesSAT, usosCFDISAT
    }
}