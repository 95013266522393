import {
    Button,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { CloudUpload, Description, HorizontalRule } from '@mui/icons-material';
import {
    makeStyles
} from "@mui/styles";
import {
    IGasForm,
    IGasTextField,
    IGasSelectField,
    SuccessMessage,
} from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { insert } from "../../../store/catalogos/plantillasImpresiones";
import { listaTiposDoc } from "../../../helpers/enums/tiposDocumento";
import { DropzoneArea } from "react-mui-dropzone";

export function InsertDialog({ open, onClose, postSubmit }) {
    const dispatch = useDispatch();
    const { loading, error } = useSelector((state) => state.plantillas);
    const [errors, setErrors] = useState({});
    const [files, setFiles] = useState([]);
    const [fileName, setFileName] = useState('');
    const [icono, setIcono] = useState('')
    const mensajeInicial = 'Arrastre y suelte su plantilla aquí o haga clic para cargarla'
    const [textoDropZone, setTextoDropZone] = useState(mensajeInicial)
    const EmptyIcon = (props) => <HorizontalRule sx={{ color: "transparent", height: '0px', width: '0px' }} />;
    const InitialIcon = (props) => <CloudUpload sx={{ color: "#3D474D", height: '60px', width: '60px' }} />

    const successMsg = useModal(false);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        onClose();
    };

    const onSubmit = (data) => {
        setErrors('')
        let fail = false
        if (files?.length === 0) {
            fail = true
            setErrors((e) => ({
                dropzoneArea: "Plantilla es requerida.",
            }))
        }
        if (data.Descripcion === '' || data.Descripcion === ' ' || data.Descripcion === undefined) {
            fail = true
            setErrors((e) => ({
                ...e,
                descripcion: "Descripción es requerida.",
            }))
        }
        if (data.TipoDocumento === 'n') {
            fail = true
            setErrors((e) => ({
                ...e,
                tipoDocumento: "Tipo de documento es requerido.",
            }))
        }
        if (fail) {
            return
        } else {

            const formData = new FormData();
            formData.append('plantilla', files[0]);
            formData.append('tipoDocumento', data.TipoDocumento);
            formData.append('descripcion', data.Descripcion === undefined ? '' : data.Descripcion);
            dispatch(insert(formData))
                .then(hasDispatchError)
                .then(onClose)
                .then(successMsg.open)
                .then(postSubmit)
                .catch(() => { });
        }
    };

    function initDialog() {
        if (open) {
            setErrors("");
            setFiles([])
            setFileName("")
            setTextoDropZone(mensajeInicial)
            setIcono(() => InitialIcon)
        }
    }

    const handleIcon = () => {
        return <Description sx={{ color: '#F09536', height: '60px', width: '60px' }} />
    }

    const handleDrop = (files) => {
        setErrors("");
        setTextoDropZone("")
        setIcono(() => EmptyIcon);
    }

    const handleDropReject = () => {
        setErrors((e) => ({
            dropzoneArea: "Solo se permiten archivos de extensión .docx con un peso máximo de 1 MB.",
        }));
    }

    const handleDelete = () => {
        setFiles([])
        setFileName('')
        setTextoDropZone(mensajeInicial)
        setIcono(() => InitialIcon)
        setErrors('');
    }

    const handleFileChange = (newFiles) => {
        const maxLength = 30;
        if (newFiles[0]?.name) {
            const fileName = newFiles[0]?.name;
            const shortName = fileName.length > maxLength
                ? fileName.slice(0, -11) + "..." + "(.docx)"
                : fileName;
            setFileName(shortName)
        }
        setFiles(newFiles);
    };

    const assignErrors = () => {
        if (open & (error?.status === 400))
            setErrors(getModelErrors(error.message));
    };

    const useStyles = makeStyles({
        dropzone: {
            minHeight: "45px",
            maxHeight: "170px",
        },
        dropzoneText: {
            margin: "3px",
            padding: "10px",
            fontSize: "18px",
            color: "#8C8C8C",
        },
    });

    const theme = createTheme({
        components: {
            MuiDropzonePreviewList: {
                styleOverrides: {
                    imageContainer: {
                        marginTop:"1px !important",
                        paddingTop: "17px !important",
                    },
                    removeButton: {
                        marginTop: "10px !important",
                        top: "10px",
                    },
                },
            },
        }
    });

    const classes = useStyles();

    useEffect(initDialog, [open]);
    useEffect(assignErrors, [error]);

    return (
        <>
            <Dialog
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                fullWidth
                disableEscapeKeyDown
            >
                <DialogTitle onClose={onClose}>Agregar plantilla de impresión</DialogTitle>
                <DialogContent dividers={true}>
                    <IGasForm id="form-update-dialog" onSubmit={onSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <IGasSelectField
                                    name="TipoDocumento"
                                    label="Tipo de documento"
                                    defaultValue={'n'}
                                    error={errors?.tipoDocumento}
                                >
                                    <MenuItem key={'n'} value='n' disabled>
                                        <em>Seleccione tipo de documento</em>
                                    </MenuItem>
                                    {listaTiposDoc.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {`${option.descripcion}`}
                                        </MenuItem>
                                    ))}
                                </IGasSelectField>
                            </Grid>
                            <Grid item xs={6}>
                                <IGasTextField
                                    name="Descripcion"
                                    label="Descripción"
                                    maxLength={20}
                                    error={errors?.descripcion}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl id="form-update2" variant="standard" sx={{ m: 1, width: 532 }} error={!!errors?.dropzoneArea}>
                                    <ThemeProvider theme={theme}>
                                        <DropzoneArea
                                            disableRejectionFeedback
                                            dropzoneText={textoDropZone}
                                            dropzoneClass={classes.dropzone}
                                            dropzoneParagraphClass={classes.dropzoneText}
                                            filesLimit={1}
                                            Icon={icono}
                                            getPreviewIcon={handleIcon}
                                            showPreviewsInDropzone={true}
                                            showFileNames={false}
                                            acceptedFiles={['application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                                            previewGridProps={{
                                                container: {
                                                    paddingLeft: '1px', direction: 'row', justifyContent: 'center', // Centra los elementos horizontalmente
                                                    alignItems: 'center',
                                                },
                                                item: {
                                                    paddingTop: '1px'
                                                }
                                            }}
                                            onDrop={handleDrop}
                                            onDropRejected={handleDropReject}
                                            onDelete={handleDelete}
                                            onChange={handleFileChange}
                                            maxFileSize={1000000} // 1 MB en bytes
                                            showAlerts={false}
                                        />
                                    </ThemeProvider>
                                    {files && <Typography variant="body2" style={{ textAlign: 'center' }}>
                                        {fileName}
                                    </Typography>}
                                    {errors?.dropzoneArea && (
                                        <FormHelperText>{errors.dropzoneArea}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </IGasForm>
                </DialogContent>

                <DialogActions>
                    <Button
                        color="secondary"
                        disableElevation
                        disabled={loading.insert}
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="secondary"
                        disableElevation
                        disabled={loading.insert}
                        type="submit"
                        form="form-update-dialog"
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>

            <SuccessMessage
                open={successMsg.isShowing}
                onClose={successMsg.close}
                text="Se agregó correctamente."
            />
        </>
    );
}

export default InsertDialog;
