import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { clearErrors, getAll, setContacto, setContactoIdSelected } from "../../store/catalogos/contactos";
import { Catalogo, ColumnAlign, ColumnPriority, ColumnType, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import { DeleteDialog, InsertDialog, OptionTableRow, UpdateDialog } from "../../components/catalogos/contactos";
import { FilterDrawer } from "../../components/catalogos/contactos/filterDrawer";

const ACTIVO = (value) =>
    value.activo ? (
        <CheckCircleOutline fontSize='small' color='primary' />
    ) : (
        <RadioButtonUnchecked fontSize='small' color='action' />
    );

const CORREO = (value) =>
    (value.correoElectronico === null) ? (
        <>{''}</>
    ) : (
        <>{value.correoElectronico}</>
    );

    const TELEFONO = (value) =>
        (value.telefono === null) ? (
            <>{''}</>
        ) : (
            <>{value.telefono}</>
        );

const TABLE_DEFAULT = {
    columns: [
        { text: "Nombre", name: "nombre", orderDefault: true, colAlign: ColumnAlign.Left, colType: ColumnType.String, Colpriority: ColumnPriority.High },
        { text: "Correo electrónico", name: "correoElectronico", colAlign: ColumnAlign.Left, renderCell: CORREO, Colpriority: ColumnPriority.High },
        { text: "Télefono", name: "telefono", colAlign: ColumnAlign.Left, colType: ColumnType.String, renderCell: TELEFONO, Colpriority: ColumnPriority.High },
        {
            text: "Activo",
            name: "activo",
            colAlign: ColumnAlign.Center,
            icon: true,
            renderCell: ACTIVO,
            Colpriority: ColumnPriority.High
        },
        { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
    ],
};

export function Contactos() {
    const dispatch = useDispatch();
    const { loading, contactos, error, filter } = useSelector((state) => state.contactos);
    const { clienteIdSelected, cliente } = useSelector((state) => state.clientes);
    const navigate = useNavigate();
    const modalInsert = useModal(false);
    const modalEdit = useModal(false);
    const modalDelete = useModal(false);

    const dangerMsg = useModal(false);
    const warningMsg = useModal(false);

    const handleRefresh = () => {
        if (clienteIdSelected === undefined || clienteIdSelected === '')
            navigate("/catalogos/clientes");
        else {
            var peticion = filter.activo.state ? `${clienteIdSelected}?Activo=${filter.activo.value}`
                : clienteIdSelected
            dispatch(getAll(peticion))
        }
    };

    const handleError = () => {
        if (Boolean(error?.status)) {
            error.status === 409 && warningMsg.open();
            error.status === 500 && dangerMsg.open();
        }
    };

    const handleCloseError = () => {
        dispatch(clearErrors());
        dangerMsg.close();
        warningMsg.close();
    };

    useEffect(handleError, [error]);

    return (
        <>
            <Catalogo
                title={'Contactos'}
                subtitle={cliente?.razonSocial}
                table={TABLE_DEFAULT}
                data={contactos}
                filter={filter}
                filterPanel={<FilterDrawer />}
                loading={loading.getAll}
                onRefresh={handleRefresh}
                onInsert={modalInsert.open}
                rowOptions={
                    <OptionTableRow
                        onSelect={(d) => {
                            dispatch(setContactoIdSelected(d.id));
                            dispatch(setContacto(d));
                        }}
                        onEdit={modalEdit.open}
                        onDelete={modalDelete.open}
                    />
                }
            />

            <InsertDialog open={modalInsert.isShowing} onClose={modalInsert.close} postSubmit={handleRefresh} />
            <UpdateDialog open={modalEdit.isShowing} onClose={modalEdit.close} postSubmit={handleRefresh} />
            <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />

            <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
            <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
        </>
    );
}
