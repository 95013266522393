import api from "../service";

 const controller = 'Cliente/Direcciones';

export async function GetAll(id) {
    const res = await api.get(`${controller}/${id}`);
  return res.data;
}

export async function GetId(id) {
    const res = await api.get(`${controller}/byId/${id}`);
  return res.data;
}

export async function Insert(params) {
    const res = await api.post(controller, params);
  return res.data;
}

export async function Update(params) {
    const res = await api.put(controller, params);
  return res.data;
}

export async function SetMatriz(params) {
  const res = await api.put(`${controller}/setMatriz/${params}`);
return res.data;
}

export async function Delete(id) {
    const res = await api.delete(`${controller}/${id}`);
  return res.data;
}

export default {
  GetAll,
  GetId,
  Insert,
  SetMatriz,
  Update,
  Delete
};
