import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, FormHelperText, InputLabel, FormControl, Select, TextField, LinearProgress } from "@mui/material";
import { IGasForm, IGasTextField, SuccessMessage } from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { insert } from "../../../store/catalogos/direcciones";
import { getEstadoByCodigoPostal } from "../../../store/consultas/catalogosSAT";

export function InsertDialog({ open, onClose, postSubmit, catalagosSAT }) {
    const dispatch = useDispatch();
    const { paisesSAT } = catalagosSAT
    const { loading, error } = useSelector(state => state.direcciones);
    const { loading: catalagosLoading, error: catalagosError } = useSelector(state => state.catalogosSAT);
    const { cliente } = useSelector(state => state.clientes);
    const [errors, setErrors] = useState({});
    const successMsg = useModal(false);

    const [selectedPais, setSelectedPais] = useState('0');
    const [selectedEstado, setSelectedEstado] = useState('0');
    const [paisesFiltrados, setPaisesFiltrados] = useState([]);
    const [codigoPostal, setCodigoPostal] = useState('');


    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return
        onClose();
    }

    const handleSubmit = (data) => {
        setErrors({});
        data.ClienteId = cliente.id;
        data.Matriz = false;
        data.PaisSATId = selectedPais;
        data.CodigoPostal = codigoPostal;
        data.EstadoSATId = selectedEstado != "0" ? selectedEstado : null;

        dispatch(insert(data))
            .then(hasDispatchError)
            .then(onClose)
            .then(successMsg.open)
            .then(postSubmit)
            .catch(() => { });
    };

    function initDialog() {
        if (open) {
            setSelectedEstado('0')
            setCodigoPostal('')
            setErrors({});
            const paisMexico = paisesSAT.sort((a, b) => a.descripcion.localeCompare(b.descripcion))
                .find(pais => pais.descripcion === "México");
            if (paisMexico) {
                setSelectedPais(paisMexico.id);
            } else {
                setSelectedPais(paisesSAT[0]?.id);
            }
            const selectedOption = paisesSAT.find(option => option.id === paisMexico?.id);
            if (selectedOption) {
                const estadosCopia = [...selectedOption.estados];
                setPaisesFiltrados(estadosCopia.sort((a, b) => a.descripcion.localeCompare(b.descripcion)));
            }
        }
    }

    const assignErrors = () => {
        if (open & (error.status === 400)) setErrors(getModelErrors(error.message));
    };

    const assignCatalagosError = () => {
        if (open & (catalagosError.status === 409)) setErrors((prevErrors) => ({
            ...prevErrors,
            codigoPostal: 'Código postal no existe.',
          }));
    };

    const handlePaisSATChange = (event) => {
        setSelectedEstado('0')

        const selectedId = event.target.value;
        setSelectedPais(selectedId)
        const selectedOption = paisesSAT.find(option => option.id === selectedId);
        if (selectedOption) {
            const estadosCopia = [...selectedOption.estados];
            setPaisesFiltrados(estadosCopia.sort((a, b) => a.descripcion.localeCompare(b.descripcion)));
        } else {
            setPaisesFiltrados([]);
        }
    };

    const handleEstadoChange = (event) => {
        setSelectedEstado(event.target.value);
    };

    const handleKeyPress = (event) => {
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
        const isNumber = /^\d$/.test(event.key);
        if (!isNumber && !allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    const handleCPChange = (event) => {
        setErrors({});
        const cpRegex = /^\d{5}$/
        const codigoPostal = event.target.value;
        const cpFormato = cpRegex.test(codigoPostal);
        const paisMexico = paisesSAT.find(pais => pais.descripcion === "México");
        setCodigoPostal(codigoPostal)

        if (codigoPostal.length === 5) {
            if (cpFormato && selectedPais === paisMexico.id) {
                dispatch(getEstadoByCodigoPostal(codigoPostal))
                    .then(({ payload }) => {
                        payload?.status === 409 
                        ? setSelectedEstado('0') 
                        : setSelectedEstado(payload.estadoSATId)
                    })
                    .catch(() => { });
            }
        }
    }

    useEffect(initDialog, [open]);
    useEffect(assignErrors, [error]);
    useEffect(assignCatalagosError, [catalagosError]);

    return (
        <>
            <Dialog
                maxWidth='sm'
                open={open}
                onClose={handleClose}
                fullWidth
                disableEscapeKeyDown>
                {(catalagosLoading.getEstadoByCodigoPostal) && <LinearProgress />}
                <DialogTitle onClose={onClose}>Agregar dirección</DialogTitle>
                <DialogContent dividers={true}>
                    <IGasForm id='form-update-dialog' onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={10} sx={{ marginTop: '3px' }}>
                                <IGasTextField
                                    name="Direccion"
                                    label='Dirección'
                                    defaultValue=''
                                    error={errors?.direccion}
                                    maxLength={200}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <FormControl variant="standard" fullWidth error={!!errors?.codigoPostal}>
                                    <TextField
                                        name="CodigoPostal"
                                        label="C.P."
                                        variant="standard"
                                        value={codigoPostal}
                                        error={errors?.codigoPostal}
                                        onChange={handleCPChange}
                                        onKeyDown={handleKeyPress}
                                        inputProps={{
                                            maxLength: 5,
                                        }}
                                    />
                                    {errors?.codigoPostal && (
                                        <FormHelperText>{errors?.codigoPostal}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl variant="standard" fullWidth error={!!errors?.PaisSATId}>
                                    <InputLabel variant="standard" >
                                        País
                                    </InputLabel>
                                    <Select
                                        name="PaisSATId"
                                        label={"País"}
                                        value={selectedPais}
                                        error={!!errors?.PaisSATId}
                                        fullWidth
                                        onChange={handlePaisSATChange}
                                        disabled={catalagosLoading.getEstadoByCodigoPostal}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        }}
                                    >
                                        {paisesSAT
                                            .map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {`${option.descripcion}`}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {errors?.PaisSATId && (
                                        <FormHelperText>{errors.PaisSATId}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={7}>
                                <FormControl variant="standard" fullWidth error={!!errors?.EstadoSATId}>
                                    <InputLabel variant="standard" >
                                        Estado
                                    </InputLabel>
                                    <Select
                                        name="EstadoSATId"
                                        label={"Estado"}
                                        value={selectedEstado}
                                        error={!!errors?.EstadoSATId}
                                        fullWidth
                                        onChange={handleEstadoChange}
                                        disabled={catalagosLoading.getEstadoByCodigoPostal}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value='0' disabled>
                                            <em>Seleccione un estado (opcional)</em>
                                        </MenuItem>
                                        {paisesFiltrados.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {`${option.descripcion}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors?.EstadoSATId && (
                                        <FormHelperText>{errors.EstadoSATId}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </IGasForm>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" disableElevation disabled={loading.insert} onClick={handleClose}>Cancelar</Button>
                    <Button color="secondary" disableElevation disabled={loading.insert || catalagosLoading.getEstadoByCodigoPostal} type="submit" form='form-update-dialog'>Guardar</Button>
                </DialogActions>
            </Dialog>
            <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se agregó correctamente.' />
        </>
    )
}
