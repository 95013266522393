import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import {
  IGasForm,
  IGasSwitchField,
  IGasSelectField,
  SuccessMessage,
  IGasTextField,
} from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getById, update } from "../../../store/catalogos/seriesDocumentos";

export function UpdateDialog({ open, onClose, postSubmit, tiposDocumentos }) {
  const dispatch = useDispatch();
  const { loading, serieDocumento, serieDocumentoSelectedId, error } =
    useSelector((state) => state.seriesDocumentos);
  const [errors, setErrors] = useState({});
  const successMsg = useModal(false);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const handleSubmit = (data) => {
    data.id = serieDocumentoSelectedId;
    dispatch(update(data))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const initDialog = () => {
    if (open) {
      setErrors({});
      dispatch(getById(serieDocumentoSelectedId))
        .then(hasDispatchError)
        .catch(() => {});
    }
  };

  const assignErrors = () => {
    if (open & (error?.status === 400))
      setErrors(getModelErrors(error.message));
  };

  useEffect(initDialog, [open]);
  useEffect(assignErrors, [error]);

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        fullWidth
        disableEscapeKeyDown
      >
        <DialogTitle onClose={onClose}>Editar serie de documento</DialogTitle>
        <DialogContent dividers={true}>
          {loading.getId && <>Cargando...</>}
          {!loading.getId && (
            <IGasForm id="form-update-dialog" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <IGasSelectField
                    name="TipoDocumento"
                    label="Tipo de documento"
                    defaultValue={serieDocumento?.tipoDocumento}
                    error={errors?.tipoDocumento}
                  >
                    {tiposDocumentos.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {`${option.descripcion}`}
                      </MenuItem>
                    ))}
                  </IGasSelectField>
                </Grid>

                <Grid item xs={6}>
                  <IGasTextField
                    name="Serie"
                    label="Serie"
                    defaultValue={serieDocumento.serie}
                    maxLength={25}
                    error={errors?.serie}
                  />
                </Grid>

                <Grid item xs={12}>
                  <IGasTextField
                    name="descripcion"
                    label="Descripción"
                    maxLength={50}
                    defaultValue={serieDocumento.descripcion}
                    error={errors?.descripcion}
                  />
                </Grid>

                <Grid item xs={16} sm={2} sx={{ mt: 1 }}>
                  <IGasSwitchField
                    name="activo"
                    defaultValue={serieDocumento.activo}
                    label="Activo"
                    disabled={loading.update}
                  />
                </Grid>
              </Grid>
            </IGasForm>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            disableElevation
            disabled={loading.insert}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            color="secondary"
            disableElevation
            disabled={loading.insert}
            type="submit"
            form="form-update-dialog"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage
        open={successMsg.isShowing}
        onClose={successMsg.close}
        text="Se modificó correctamente."
      />
    </>
  );
}
