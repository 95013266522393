import { Stepper, InputLabel, Step, StepLabel, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, Checkbox, checked, ListItemText, MenuItem, Tabs, Tab, Box } from "@mui/material";
import { DetailInfo, IGasForm, IGasSelectField, SuccessMessage, IGasTextField, IGasSwitchField } from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { update, getId, setCliente } from "../../../store/catalogos/clientes";

function StepPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`step-panel-${index}`}
            aria-labelledby={`step-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export function DetailDialog({ open, onClose, catalagosSAT, formasPagoAdmin }) {
    const dispatch = useDispatch();
    const { paisesSAT, usosCFDISAT } = catalagosSAT
    const { loading, error, clienteIdSelected, cliente } = useSelector(state => state.clientes);
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({});
    const successMsg = useModal(false);

    const [selectedUsoCFDI, setSelectedUsoCFDI] = useState('');
    const [regimenesSeleccionados, setRegimenesSeleccionados] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [checked, setChecked] = useState([0]);

    const handleNext = () => {
        setErrorMessage("");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCargaRegimenes = () => {
        setSelectedUsoCFDI(cliente.usoCFDISATId);
        const selectedOption = usosCFDISAT.find(option => option.id === cliente.usoCFDISATId);
        if (selectedOption) {
            setRegimenesSeleccionados(selectedOption.regimenesFiscalesSAT.sort((a, b) => a.clave.localeCompare(b.clave)));
            cliente.regimenesFiscalesSATIds.forEach(id => {
                setChecked(cliente.regimenesFiscalesSATIds)
            });
        } else {
            setRegimenesSeleccionados([]);
        }
    };

    const handleUsoCFDIChange = (event) => {
        const selectedId = event.target.value;
        setSelectedUsoCFDI(selectedId);

        const selectedOption = usosCFDISAT.find(option => option.id === selectedId);
        if (selectedOption) {
            setRegimenesSeleccionados(selectedOption.regimenesFiscalesSAT.sort((a, b) => a.clave.localeCompare(b.clave)));
        } else {
            setRegimenesSeleccionados([]);
        }
    };

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick')
            return
        setErrorMessage("");
        setRegimenesSeleccionados([]);
        setActiveStep(0);
        setChecked([0]);
        onClose();
    }

    function initDialog() {
        if (open) {
            setErrors({});
            setActiveStep(0);
            dispatch(getId(clienteIdSelected))
                .then(hasDispatchError)
                .catch(() => { });
        }
    }

    useEffect(() => {
        if (!loading.getId) {
            handleCargaRegimenes();
        }
    }, [loading.getId]);

    const assignErrors = () => {
        if (open & (error.status === 400)) setErrors(getModelErrors(error.message));
    };

    useEffect(initDialog, [open]);
    useEffect(assignErrors, [error]);
    const steps = ['Datos', 'Configuraciones'];

    return (
        <>
            <Dialog
                maxWidth='sm'
                open={open}
                onClose={handleClose}
                fullWidth
                disableEscapeKeyDown>
                <DialogTitle onClose={onClose}>Detalle cliente</DialogTitle>
                <DialogContent dividers={true}>
                    {loading.getId && <>Cargando...</>}
                    {!loading.getId && (
                        <IGasForm id='form-update-dialog'>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <StepPanel value={activeStep} index={0}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <DetailInfo
                                            title='RFC'
                                            description={cliente?.rfc}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IGasSelectField
                                            name="FormaPagoAdminId"
                                            label='Forma de Pago Default'
                                            defaultValue={parseInt(cliente?.formaPagoAdminId)}
                                            disabled={true}
                                        >
                                            {formasPagoAdmin.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {`${option.descripcion}`}
                                                </MenuItem>
                                            ))}
                                        </IGasSelectField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DetailInfo
                                            title='Razón Social'
                                            description={cliente?.razonSocial}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DetailInfo
                                            title='Nombre Comercial'
                                            description={cliente?.nombreComercial}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IGasSwitchField
                                            name='activo'
                                            defaultValue={cliente.activo}
                                            label='Activo'
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </StepPanel>
                            <StepPanel value={activeStep} index={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <IGasSelectField
                                            name="UsoCFDISATId"
                                            value={selectedUsoCFDI}
                                            defaultValue={cliente.usoCFDISATId}
                                            error={errorMessage?.UsoCFDISATId}
                                            onChange={handleUsoCFDIChange}
                                            label={"Uso CFDI Default"}
                                            disabled={true}
                                        >
                                            {usosCFDISAT.sort((a, b) => a.clave.localeCompare(b.clave)).map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {`${option.clave} - ${option.descripcion.length < 57 ? option.descripcion : option.descripcion.substring(0, 57)}`}
                                                </MenuItem>
                                            ))}
                                        </IGasSelectField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {regimenesSeleccionados.length > 0 && (<InputLabel variant="standard" sx={{
                                            fontSize: '0.7rem',
                                            color: '#A2A2A2',
                                        }}>
                                            Regímenes Fiscales Default
                                        </InputLabel>)}
                                        <List dense={true}
                                            name="RegimenesFiscalesSATIds"
                                            label='Regimenes fiscales SAT'
                                            sx={{
                                                maxHeight: 200,
                                                overflow: 'auto',
                                            }}
                                        >
                                            {regimenesSeleccionados && regimenesSeleccionados.length > 0 ? (
                                                regimenesSeleccionados.map((regimen) => (
                                                    <ListItem key={regimen.id} disablePadding>
                                                        <ListItemButton role={undefined} dense>
                                                            <Checkbox
                                                                edge="start"
                                                                checked={checked.includes(regimen.id)}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                disabled={true}
                                                            />
                                                            <ListItemText
                                                                primary={`${regimen.clave} - ${regimen.descripcion}`}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))
                                            ) : (
                                                <ListItem>
                                                    <ListItemText primary="Seleccione uso de CFDI." />
                                                </ListItem>
                                            )}
                                        </List>
                                    </Grid>
                                </Grid>
                            </StepPanel>
                        </IGasForm>
                    )}
                </DialogContent >
                <DialogActions>
                    <Button color="secondary" disableElevation disabled={loading.insert} onClick={handleClose}>Cancelar</Button>
                    {activeStep === 0 ? (
                        <Button color="secondary" disableElevation onClick={handleNext}>Siguiente</Button>
                    ) : (
                        <>
                            <Button color="secondary" disableElevation onClick={handleBack}>Atrás</Button>
                            <Button color="secondary" disableElevation disabled={loading.insert} onClick={handleClose}>Cerrar</Button>
                        </>
                    )}
                </DialogActions>
            </Dialog >
            <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se agregó correctamente.' />
        </>
    )
}
