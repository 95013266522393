import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import {
    Catalogo,
    ColumnAlign,
    ColumnPriority,
    ColumnType,
    DangerMessage,
    WarningMessage,
} from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { FilterDrawer, InsertDialog, OptionTableRow, UpdateDialog } from "../../components/catalogos/plantillasImpresiones";
import { getAll, clearErrors, setPlantillaIdSelected, get, getLink } from "../../store/catalogos/plantillasImpresiones";
import { DeleteDialog } from "../../components/catalogos/plantillasImpresiones/deleteDialog";

const ACTIVO = (value) =>
    value.activo ? (
        <CheckCircleOutline fontSize="small" color="primary" />
    ) : (
        <RadioButtonUnchecked fontSize="small" color="action" />
    );

const TABLE_DEFAULT = {
    columns: [
        {
            text: "Tipo de documento",
            name: "tipoDocumentoDescripcion",
            colAlign: ColumnAlign.Left,
            colType: ColumnType.String,
            Colpriority: ColumnPriority.High,
        },
        {
            text: "Descripción",
            name: "descripcion",
            colAlign: ColumnAlign.Left,
            colType: ColumnType.String,
            Colpriority: ColumnPriority.High,
        },
        {
            text: "Fecha de subida",
            name: "fechaSubida",
            colAlign: ColumnAlign.Left,
            colType: ColumnType.Date,
            orderDefault: true,
            Colpriority: ColumnPriority.High,
        },
        {
            text: "Activo",
            name: "activo",
            colAlign: ColumnAlign.Center,
            icon: true,
            renderCell: ACTIVO,
            Colpriority: ColumnPriority.High,
        },
        {
            text: "",
            name: "action",
            colAlign: ColumnAlign.Center,
            options: true,
            icon: true,
        },
    ],
};

export function PlantillasImpresiones() {
    const dispatch = useDispatch();
    const { loading, error, plantillas, filter } = useSelector((state) => state.plantillas);

    const modalInsert = useModal(false);
    const modalDelete = useModal(false);
    const modalEdit = useModal(false);
    const dangerMsg = useModal(false);
    const warningMsg = useModal(false);

    const handleRefresh = () => {
        dispatch(getAll(filter));
    };

    const handleError = () => {
        if (Boolean(error?.status)) {
            error.status === 409 && warningMsg.open();
            error.status === 500 && dangerMsg.open();
        }
    };

    const handleCloseError = () => {
        dispatch(clearErrors());
        dangerMsg.close();
        warningMsg.close();
    };

    useEffect(handleError, [error]);

    return (
        <>
            <Catalogo
                title="Plantillas de impresión"
                table={TABLE_DEFAULT}
                data={plantillas}
                onInsert={modalInsert.open}
                order="desc"
                filter={filter}
                filterPanel={<FilterDrawer />}
                loading={loading.getAll}
                onRefresh={handleRefresh}
                showFilterData={true}
                rowOptions={
                    <OptionTableRow
                        onSelect={(d) => {
                            dispatch(setPlantillaIdSelected(d.id));
                            dispatch(get(d.id));
                        }}
                        onDownload={(d) => {
                            dispatch(getLink(d.id))
                                .then((response) => {
                                    if (response?.payload) {
                                        window.open(response.payload, "_blank");
                                    }
                                })
                                .catch(() => { });
                        }}
                        onDelete={modalDelete.open}
                        onEdit={modalEdit.open}
                    />
                }
            />
            <InsertDialog
                open={modalInsert.isShowing}
                onClose={modalInsert.close}
                postSubmit={handleRefresh}
            />
            <UpdateDialog
                open={modalEdit.isShowing}
                onClose={modalEdit.close}
                postSubmit={handleRefresh}
            />
            <DeleteDialog
                open={modalDelete.isShowing}
                onClose={modalDelete.close}
                postSubmit={handleRefresh}
            />
            <DangerMessage
                open={dangerMsg.isShowing}
                onClose={handleCloseError}
                text={error?.message}
            />
            <WarningMessage
                open={warningMsg.isShowing}
                onClose={handleCloseError}
                text={error?.message}
            />
        </>
    );
}
