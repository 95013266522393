import { useDispatch, useSelector } from "react-redux";
import { InsertDialog, OptionTableRow, UpdateDialog, DeleteDialog, SetMatrizDialog } from "../../components/catalogos/direcciones";
import { useEffect, useState } from "react";
import { clearErrors, getAll, setDireccionIdSelected, setDireccion, getId } from "../../store/catalogos/direcciones";
import { Catalogo, ColumnAlign, ColumnPriority, ColumnType, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { Avatar, Tooltip, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { orange } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { useCatalagosSAT } from "../../hooks";

const DIR_MATRIZ = (value) =>
    value.matriz ? (
        <>
            <div>
                <Typography style={{ display: "inline" }}>{value.codigoPostalSAT}</Typography>
                <div style={{ display: "inline-block", marginLeft: "4px" }}>
                    <Tooltip title='Matriz'>
                        <Avatar sx={{ width: 14, height: 14, bgcolor: orange[400] }}>
                            <HomeIcon sx={{ width: "100%", height: "100%" }} />
                        </Avatar>
                    </Tooltip>
                </div>
            </div>
        </>
    ) : (
        <Typography>{value.codigoPostalSAT}</Typography>
    );

const TABLE_DEFAULT = {
    columns: [
        { text: "Código postal", name: "codigoPostalSAT", colAlign: ColumnAlign.Left, colType: ColumnType.String, Colpriority: ColumnPriority.High, renderCell: DIR_MATRIZ },
        { text: "Dirección", name: "direccion", orderDefault: true, colAlign: ColumnAlign.Left, colType: ColumnType.String, Colpriority: ColumnPriority.High },
        { text: "País", name: "paisSATClaveDescripcion", colAlign: ColumnAlign.Left, colType: ColumnType.String, Colpriority: ColumnPriority.High },
        { text: "Estado", name: "estadoSATClaveDescripcion", colAlign: ColumnAlign.Left, colType: ColumnType.String, Colpriority: ColumnPriority.High },

        { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
    ],
};

export function Direcciones() {
    const dispatch = useDispatch();
    const catalagosSAT = useCatalagosSAT();
    const { loading, direcciones, error } = useSelector((state) => state.direcciones);
    const { clienteIdSelected, cliente } = useSelector((state) => state.clientes);
    const navigate = useNavigate();
    const modalInsert = useModal(false);
    const modalEdit = useModal(false);
    const modalDelete = useModal(false);
    const modalSetMatriz = useModal(false);

    const dangerMsg = useModal(false);
    const warningMsg = useModal(false);

    const handleRefresh = () => {
        if (clienteIdSelected === undefined || clienteIdSelected === '')
            navigate("/catalogos/clientes");
        else
            dispatch(getAll(clienteIdSelected));
    };

    const handleError = () => {
        if (Boolean(error.status)) {
            error.status === 409 && warningMsg.open();
            error.status === 500 && dangerMsg.open();
        }
    };

    const handleCloseError = () => {
        dispatch(clearErrors());
        dangerMsg.close();
        warningMsg.close();
    };

    useEffect(handleError, [error]);

    return (
        <>
            <Catalogo
                title={'Direcciones'}
                subtitle={cliente?.razonSocial}
                table={TABLE_DEFAULT}
                data={direcciones}
                loading={loading.getAll}
                onRefresh={handleRefresh}
                onInsert={modalInsert.open}
                rowOptions={
                    <OptionTableRow
                        onSelect={(d) => {
                            dispatch(setDireccionIdSelected(d.id));
                            dispatch(setDireccion(d));
                        }}
                        onEdit={modalEdit.open}
                        onDelete={modalDelete.open}
                        onSetMatriz={modalSetMatriz.open}
                    />
                }
            />

            <InsertDialog open={modalInsert.isShowing} onClose={modalInsert.close} postSubmit={handleRefresh} catalagosSAT={catalagosSAT} />
            <SetMatrizDialog open={modalSetMatriz.isShowing} onClose={modalSetMatriz.close} postSubmit={handleRefresh}/>
            <UpdateDialog open={modalEdit.isShowing} onClose={modalEdit.close} postSubmit={handleRefresh} catalagosSAT={catalagosSAT} />
            <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />

            <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
            <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
        </>
    );
}
