import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import aplicacion from "./aplicacion";
import combustibles from "./catalogos/combustibles";
import estaciones from "./catalogos/estaciones";
import clientes from "./catalogos/clientes";
import direcciones from "./catalogos/direcciones";
import contactos from "./catalogos/contactos";
import usuarios from "./catalogos/usuarios";
import productosDiversos from "./catalogos/productosDiversos";
import tiposPagos from "./catalogos/tiposPagos";
import formasPagos from "./catalogos/formasPagos";
import bitacora from "./consultas/bitacora";
import catalogosSAT from "./consultas/catalogosSAT";
import cajeros from "./catalogos/cajeros";
import despachadores from "./catalogos/despachadores";
import turnos from "./consultas/turnoLiquidacion";
import lecturasIniciales from "./operaciones/lecturasIniciales";
import configuracionEstacion from "./herramientas/configuracionEstacion";
import seriesDocumentos from "./catalogos/seriesDocumentos";
import plantillas from "./catalogos/plantillasImpresiones"

const reducer = combineReducers({
  aplicacion,
  combustibles,
  estaciones,
  clientes,
  direcciones,
  contactos,
  usuarios,
  productosDiversos,
  tiposPagos,
  formasPagos,
  bitacora,
  cajeros,
  despachadores,
  turnos,
  lecturasIniciales,
  configuracionEstacion,
  seriesDocumentos,
  catalogosSAT,
  plantillas,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
